import React, { useEffect,useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Navbarp from '../Components/CommonComponents/Navbarp'
import SideNav from '../Components/CommonComponents/SideNav'
import { BASE_URL } from '../Config/Config';
import { logout } from '../features/Loginuser';
import { Navigate } from 'react-router-dom';
import {formatdate} from '../Common/Dateformat';
import '../css/List.css'
import DialogModal from '../Components/CommonComponents/DialogModal';
import SmallLoader from '../Components/CommonComponents/SmallLoader';
import CouponDropDown from '../Components/Dropdown/CouponDropDown';

function AddOfferPage() {
    const token=useSelector((state) => state.loginuser.token);
    const Login = useSelector((state) => state.loginuser.Login);
    let dispatch=useDispatch();
    const [show,setShow]=useState(false);
    const [message,setMessage]=useState("");
    const [type,setType]=useState("")
    const [actionLoader,setActionLoader]=useState(false)
    const [loading,setLoading]=useState(false)
    const [coupon_id,setCoupon_id]=useState(null)
        function updatedata(e){
            setLoading(true);
            e.preventDefault();
            var offerdata =new FormData(e.target);
            offerdata.set("start_date",formatdate("startdate",offerdata.get("start_date"),));
           // offerdata.set("title",JSON.stringify(JSON.parse(offerdata.get("title"))));
            // offerdata.set("description",JSON.stringify(offerdata.get("description")))
            // console.log("title",offerdata.get("title"),typeof(offerdata.get("title")))
            // offerdata.set("config",JSON.stringify(offerdata.get("config")) )
            offerdata.set("end_date",formatdate("enddate",offerdata.get("end_date"),));
            offerdata.append("coupon_id",coupon_id);
            fetch(BASE_URL+"addnewOffer", {
                method: "POST",
                headers: {
                  //'X-XSRF-TOKEN': 'XSRF-TOKEN)',
                      'Accept': 'application/json',
                    "Authorization": "Bearer "+token,
                },
                body:offerdata,
                
                })
                
                .then((response) => {
                  if (response.status===401){
                    dispatch(logout());
                  }else{
                    return response.json()
                  }
                })
                //Then with the data from the response in JSON...
                .then((data) => {
                  setType("status");
                  setShow(true);
                  setMessage(data.message);
                  setActionLoader(false)
                  setLoading(false);
                  //e.target.reset();
                })
        }
  function modelAction(show){
    setShow(show);
    }      
  if(Login==null||Login==false){
    return  <Navigate to="/" replace={true} />
    }    
  return (
    <>
    <DialogModal 
        show={show} 
        message={message} 
        action={modelAction} 
        id="" 
        type={type}
        actionLoader={actionLoader}
       />
      <div className="container-fluid ps-0 pe-0">
        <div className="row">
          <div>
           <Navbarp />
          </div>
          <div>
            <h3 className='ms-3'style={{'margin-top':'200px'}}>Add Offer</h3>
                <form onSubmit={updatedata}>   
                    <div className='mt-4'>
                        <div className="row align-items-start ">
                           <div className="ms-3 mb-3 col-12 col-lg-4 me-1">
                                <label  className="form-label">Title</label>
                                <textarea type="text" name="title" className="form-control" required/>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-7 me-1">
                                <label  className="form-label">Attached Coupon</label>
                                {/* <input type="text"  name="coupon_id" className="form-control" required />
                                <div className="form-text">.</div> */}
                                <CouponDropDown column={"code"} coupon_id={setCoupon_id}/>
                            </div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className="row align-items-start ">
                        <div className="ms-3 mb-3 col-12 col-lg-6 me-1">
                                <label  className="form-label">Description</label>
                                <textarea type="date"  name="description" className="form-control" required  />
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-5 me-1">
                                <label  className="form-label">Configuration</label>
                                <textarea type="date"  name="config" className="form-control" required/>
                            </div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className="row align-items-start ">
                        <div className="ms-3 mb-3 col  col-lg-6 me-1">
                                <label  className="form-label">Start Date</label>
                                <input type="date"  name="start_date" className="form-control" required  />
                                <div className="form-text">Offer Start Date.</div>
                            </div>
                            <div className="ms-3 mb-3 col me-1">
                                <label  className="form-label">End Date</label>
                                <input type="date"  name="end_date" className="form-control" required/>
                                <div className="form-text">Offer End Date.</div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className="row align-items-start ">
                            <div className=" ms-3 mb-3 col me-4">
                                <label  className="form-label">Active Status</label>
                                <select className="form-select text-right" aria-label="Default select example" name="is_active" defaultValue="Active">
                                      <option value="1">Active</option>
                                      <option value="0">Inactive</option>
                                </select>
                                <div className="form-text"></div>
                            </div>
                            
                        </div>
                    </div>
                    <button type="submit" className="ms-3 btn btn-warning mt-3 btn-lg" >
                    {loading?<SmallLoader loadingtext={"Submitting..."}/>:"Submit"}
                    </button>
                </form>   
                     
          </div> 
        
        </div>
      </div>
  </>
  )
}

export default AddOfferPage