import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { BASE_URL } from '../../Config/Config';
import { useDispatch, useSelector } from 'react-redux';
import SmallLoader from './SmallLoader';

function Otpmodal(props) {
  console.log(props)
  const token=useSelector((state) => state.loginuser.token);  
    const [otp,setOtp]=useState(null)
    return (
      <>
        <Modal backdrop="static" show={props.show} onHide={()=>props.modalaction(false)}>
          <Modal.Header >
            <Modal.Title>Please enter the OTP </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Control
                  type="number"
                  placeholder="XXXXXX"
                  autoFocus
                  required
                  onChange={(e)=>setOtp(e.target.value)}
                />
                <Form.Text className="text-danger ms-2 mt-3 fs-5 fw-bold ">
                {props.message} 
                </Form.Text>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={()=>props.modalaction(false)}>
              Close
            </Button>
            <Button type='submit' variant="warning" onClick={()=>props.action(otp)}>
            {props.actionLoader?<SmallLoader loadingtext={"verifying..."}/>:"Verify"}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
      )
}
export default Otpmodal