import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Navbarp from '../Components/CommonComponents/Navbarp';
import SideNav from '../Components/CommonComponents/SideNav';
import { TextField, Grid } from '@mui/material';
import { useState,useEffect } from 'react';
import CustomerList from '../Components/List/CustomerList';
import {BASE_URL} from '../Config/Config';
import { logout } from '../features/Loginuser';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import '.././css/List.css'
import '.././css/Card.css'
import '.././css/Loader.css'
import'.././css/dashboard.css'
import Loader from '../Components/CommonComponents/Loader';
import DialogModal from '../Components/CommonComponents/DialogModal';
import Calendar from '../Components/CommonComponents/Calendar';
function Customers() {
  const dispatch=useDispatch();
  const [list, setList] = useState([]);
  const Login = useSelector((state) => state.loginuser.Login);
  const token=useSelector((state) => state.loginuser.token);
  const [lastPage, setLastPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading,setLoading]=useState(true)
  const [totalAccount,setTotalAccount]=useState(0) 
  const [kisan,setKisan]=useState(0);
  const [dairyOwner,setDairyOwner]=useState(0)
  const [count, setCount] = useState(0);
  const [sortingby,setSortingBy]=useState(1)
  const [show,setShow]=useState(false);
  const [message,setMessage]=useState("");
  const [type,setType]=useState("")
  const [customerid,setCustomerId]=useState(null)
  const [actionLoader,setActionLoader]=useState(false)
  const [firstLoading,setFirstLoading]=useState(true)
   const [searchBy,setSearchBy]=useState({
      "records":100,
    });
  useEffect(() => {
    if(firstLoading){
      getcustomerlist();
      setFirstLoading(false)
    }else{
      const getData = setTimeout(() => {
        getcustomerlist();
      }, 200)
      return () => clearTimeout(getData)
    }
    
    
  }, [currentPage,searchBy])

  function getcustomerlist(){
    let searchstr=searchstring(searchBy);
      fetch(BASE_URL+"customerlist?page="+currentPage+"&"+searchstr, {
          method: "GET",
          headers: {
            //'X-XSRF-TOKEN': 'XSRF-TOKEN)',
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              "Authorization": "Bearer "+token,
          }

          })
          .then((response) => {
            if (response.status===401){
              dispatch(logout());
            }else{
              return response.json()
            }
          })
          //Then with the data from the response in JSON...
          .then((data) => {
              setLoading(false);
              setList(data.result.data);
              setLastPage(data.result.last_page);
              setCurrentPage(data.result.current_page);
              setTotalAccount(data.totalAccount);
              setDairyOwner(data.dairyOwner);
              setKisan(data.kisan);
              setCount(data.count)
              
          })

  }

  function returnCustomerId(customer_id){
      setType("confirm")
      setMessage("Are you sure you want to delete the Customer")
      setShow(true);
      setCustomerId(customer_id);
      console.log("in get customer_id",customer_id)
    // let descision =window.confirm("Are you sure you want to delete this ");
    // console.log("descision",descision)
    
  }
    function setpage(value){
      if (value >=1&& value<=lastPage){
          setCurrentPage(value)
      }
    }
    function searchParam(value,key){
      const getData = setTimeout(() => {
        setSearchBy({...searchBy,[key]:value})
  
      }, 2000)
      return () => clearTimeout(getData)
     
    }
    const searchstring=(searchBy)=>{
      let passdata={...searchBy};
      if(searchBy.startdate!=null && searchBy.enddate!=null){
        passdata.startdate=searchBy.startdate;
        passdata.enddate=searchBy.enddate;
      }
      
      var queryString = Object.keys(passdata).map(key => key + '=' + passdata[key]).join('&');
      return queryString
    }
    var arr=[...list]
    function sort(type,key){
      if(sortingby==1){
        //code for sorting on server 
        setSearchBy({...searchBy,"sortby":key,"sorttype":type});
      }else{
      // Code for sorting in browser 
        if (type=="asc"){
         for (let i=0;i<arr.length;i++){
             for (let j=0;j<=i;j++){
                 if (arr[i][key] < arr[j][key]) {
                     var x = arr[i];
                     arr[i] =arr[j];
                     arr[j] = x;
                 }
             }
         }
      }
      if (type=="desc"){
         for (let i=0;i<arr.length;i++){
             for (let j=0;j<=i;j++){
                 if (arr[i][key] >arr[j][key]) {
                     var x = arr[i];
                     arr[i] =arr[j];
                     arr[j] = x;
                 }
             }
         }
      }
     setList(arr);
      }
     }
     function modelAction(show,descision,customer_id){
      setShow(show);
      if(descision){
        setActionLoader(true);
        deleteCustomer(customer_id);
      }else{
        setCustomerId(null);
      }
    }

    function deleteCustomer(customer_id){
      var fdata =new FormData();
      fdata.append("customer_id",customer_id)
        fetch(BASE_URL+"deletecustomer", {
          method: "POST",
          headers: {
            //'X-XSRF-TOKEN': 'XSRF-TOKEN)',
                'Accept': 'application/json',
              "Authorization": "Bearer "+token,
          },
          body:fdata,
          })
          
          .then((response) => {
            if (response.status===401){
              dispatch(logout());
            }else{
              return response.json()
            }
          })
          //Then with the data from the response in JSON...
          .then((data) => {
            setType("status");
            setShow(true);
            setMessage(data.message);
            setCustomerId(null);
            setActionLoader(false)
          })
          getcustomerlist();
      }
    
    if(Login==null||Login==false){
    return  <Navigate to="/" replace={true} />
    }
     return (
       <>

       <DialogModal 
        show={show} 
        message={message} 
        action={modelAction} 
        id={customerid} 
        type={type}
        actionLoader={actionLoader}
       />
        
         <div className="container-fluid ps-0 pe-0">
           <div className="row ">
           <div>
                <Navbarp />
               
            </div> 
             <div className='position-absolute'>
                <div className='mt-1'>
                  <Grid  container  className='justify-content-between ms-3'>
                    <div className='d-md-flex width_100 me-3'>
                          <Grid item className='me-3 mt-2' >
                            <Card className='record'>
                              <CardContent>
                                <Typography variant="h6" gutterBottom>
                                  Record Found
                                </Typography>
                                <Typography variant="h6" gutterBottom>
                                  {count}
                                </Typography>
                              </CardContent>   
                            </Card>
                          </Grid>
                          <Grid item className='me-3 mt-2'>
                            <Card className='total'>
                              <CardContent>
                                <Typography variant="h6" gutterBottom>
                                  Total Account
                                </Typography>
                                <Typography variant="h6" gutterBottom>
                                  {totalAccount}
                                </Typography>
                              </CardContent>   
                            </Card>
                          </Grid>
                          <Grid item className='me-3 mt-2'>
                            <Card className='card1'>
                              <CardContent>
                                <Typography variant="h6" gutterBottom>
                                Total Dairy 
                                </Typography>
                                <Typography variant="h6" gutterBottom>
                                  {dairyOwner}
                                </Typography>
                              </CardContent>   
                            </Card>
                          </Grid>
                          <Grid item className='me-3 mt-2'>
                            <Card className='card2'>
                              <CardContent>
                                <Typography variant="h6" gutterBottom>
                                 kisan Account
                                </Typography>
                                <Typography variant="h6" gutterBottom>
                                  {kisan}
                                </Typography>
                              </CardContent>   
                            </Card>
                          </Grid>
                    </div>
                    <div>
                      <Calendar
                        searchBy={searchBy} 
                        setSearchBy={setSearchBy}
                        setSortingBy={setSortingBy}
                        lastPage={lastPage} 
                        currentPage={currentPage} 
                        setpage={setpage} 
                        type={""}
                      />
                  </div>
                  </Grid>
                </div>
                    {loading?
                    <Loader/>:
                    <div>
                    <CustomerList data={list} searchParam={searchParam} sort={sort} returnCustomerId={returnCustomerId}/>
                    </div>
                  }
             </div>
           </div>
         </div>
       </>
     );
}

export default Customers