import React, { useEffect,useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loader from '../Components/CommonComponents/Loader';
import Navbarp from '../Components/CommonComponents/Navbarp'
import SideNav from '../Components/CommonComponents/SideNav'
import { BASE_URL } from '../Config/Config';
import { logout } from '../features/Loginuser';
import { DateTime } from 'luxon';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Navigate } from 'react-router-dom';
import DialogModal from '../Components/CommonComponents/DialogModal';
import SmallLoader from '../Components/CommonComponents/SmallLoader';

function Subscriptiondetail() {
    const token=useSelector((state) => state.loginuser.token);
    const Login = useSelector((state) => state.loginuser.Login);
    let params = useParams();
    let dispatch=useDispatch();
    const [loading,setLoading]=useState(true);
    const [subscriptionDetail,setSubscriptionDetail]=useState(true);
    const [show,setShow]=useState(false);
    const [message,setMessage]=useState("")
    const [actionLoader,setActionLoader]=useState(false);
    const [type,setType]=useState("");
        const [statusloader,setStatusLoader]=useState(false)

     
    useEffect(() => {
      getsubscriptiondetail();
            },[setSubscriptionDetail])
      function getsubscriptiondetail(){
        var fdata =new FormData();
      fdata.append("subscription_id",params.subscription_id)
          fetch(BASE_URL+"getsubscriptiondetail", {
              method: "POST",
              headers: {
                //'X-XSRF-TOKEN': 'XSRF-TOKEN)',
                    'Accept': 'application/json',
                  "Authorization": "Bearer "+token,
              },
              body:fdata,
              
              })
              
              .then((response) => {
                if (response.status===401){
                  dispatch(logout());
                }else{
                  return response.json()
                }
              })
              //Then with the data from the response in JSON...
              .then((data) => {
                  setLoading(false);
                  setSubscriptionDetail(data.subscriptionDetail[0]);
                  
              })
      }
            
        function updatedata(e){
            e.preventDefault();
            setStatusLoader(true)
            var updatedata =new FormData(e.target);
            updatedata.append("subscription_id",e.target.subscription_id.value)
            updatedata.append("customer_id",e.target.customer_id.value)
            updatedata.append("plan_id",e.target.plan_id.value)
            updatedata.append("order_id",e.target.order_id.value)
            fetch(BASE_URL+"updatesubscriptiondetail", {
                method: "POST",
                headers: {
                  //'X-XSRF-TOKEN': 'XSRF-TOKEN)',
                      'Accept': 'application/json',
                    "Authorization": "Bearer "+token,
                },
                body:updatedata,
                
                })
                
                .then((response) => {
                  if (response.status===401){
                    dispatch(logout());
                  }else{
                    return response.json()
                  }
                })
                //Then with the data from the response in JSON...
                .then((data) => {
                  setType("status");
                  setShow(true);
                  setMessage(data.message);
                  setActionLoader(false)
                  setStatusLoader(false)
                   
                })
        }
  function modelAction(show){
    setShow(show);
  }
  if(Login==null||Login==false){
    return  <Navigate to="/" replace={true} />
    }    
  return (
    <>
    <DialogModal 
        show={show} 
        message={message} 
        action={modelAction} 
        id="" 
        type={type}
        actionLoader={actionLoader}
       />
      <div className="container-fluid ps-0 pe-0">
        <div className="row">
          <div>
          <Navbarp />
          </div>
          <div>
            <h3 className='ms-3' style={{'margin-top':'200px'}}>Subscription Detail</h3>
            {loading?
            <Loader/>:
                <form onSubmit={updatedata}>   
                    <div className='mt-4'>
                        <div className="row align-items-start ">
                           <div className="ms-3 mb-3 col-12 col-lg-2">
                                <label className="form-label">Subscription Id</label>
                                <input type="number" name="subscription_id" className="form-control" defaultValue={subscriptionDetail.subscription_id} disabled />
                                <div id="emailHelp" className="form-text">Id of Subscription.</div>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2">
                                <label  className="form-label">Customer id</label>
                                <input type="text" name="customer_id" className="form-control" defaultValue={subscriptionDetail.customer_id} disabled />
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2">
                                <label  className="form-label">Plan id</label>
                                <input type="text"  name="plan_id" className="form-control" defaultValue={subscriptionDetail.plan_id} disabled/>
                            </div>
                            
                            <div className="ms-3 mb-3 col-12 col-lg-2">
                                <label className="form-label">Order id</label>
                                <input type="text" name="order_id" className="form-control" defaultValue={subscriptionDetail.order_id} disabled />
                                
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-3">
                                <label  className="form-label">In trial</label>
                                <select className="form-select text-right" aria-label="Default select example" name="is_trial" defaultValue={subscriptionDetail.is_trial} required>
                                      <option value="0">Yes</option>
                                      <option value="1">No</option>
                                  </select>
                            </div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className="row align-items-start ">
                        <div className="ms-3 mb-3 col-12 col-lg-2">
                                <label className="form-label">Trial Start Date</label>
                                <input type="date" name="trial_start_date" className="form-control" defaultValue={subscriptionDetail.trial_start_date}  />
                                
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2">
                                <label className="form-label">Trial End Date</label>
                                <input type="date" name="trial_end_date" className="form-control" defaultValue={subscriptionDetail.trial_end_date}  />
                                
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2">
                                <label className="form-label">Start Date</label>
                                <input type="date"  name="start_date" className="form-control" defaultValue={subscriptionDetail.start_date}  />
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2">
                                <label  className="form-label">End Date</label>
                                <input type="date"  name="end_date" className="form-control" defaultValue={subscriptionDetail.end_date}  />
                            </div>
                           
                            <div className="ms-3 mb-3 col-12 col-lg-3">
                                <label  className="form-label">Status</label>
                                <select className="form-select text-right" aria-label="Default select example" name="status" defaultValue={subscriptionDetail.status} required>
                                      <option value="active">Active</option>
                                      <option value="expired">Expired</option>
                                  </select>
                            </div>
                           
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className="row align-items-start ">
                        <div className="ms-3 mb-3 col-12 col-lg-5">
                                <label  className="form-label">Date Added</label>
                                <input type="text"  name="date_added" className="form-control" defaultValue={DateTime.fromISO(subscriptionDetail.date_added).toFormat("yyyy-LL-dd hh:mm a")} disabled />
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-5">
                                <label  className="form-label">Date Modified</label>
                                <input type="text"  name="date_modified" className="form-control" defaultValue={DateTime.fromISO(subscriptionDetail?.date_modified).toFormat("yyyy-LL-dd hh:mm a")} disabled  />
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-warning mt-3 btn-lg">
                    {statusloader?<SmallLoader loadingtext={"Submitting..."}/>:"Submit"}
                    </button>
              </form> }   
          </div> 
        </div>
      </div>
  </>
  )
}

export default Subscriptiondetail