import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import "../../css/Modal.css"
import SmallLoader from './SmallLoader';
function DialogModal(props) {
  return (
    <>
      {props.type=="confirm"?
      <>
        <Modal backdrop="static" show={props.show} onHide={()=>props.action(false)} >
        <Modal.Header closeButton>
          <Modal.Title className='fs-2 fw-bold text-danger p-1'>Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body className=' fs-5 fw-bold'>{props.message}</Modal.Body>
        <Modal.Footer>
          <button className='btn btn-outline-danger btn-lg me-3 m-3' onClick={()=>props.action(true,true,props.id,true)}>
            {props.actionLoader?<SmallLoader loadingtext={"Deleting..."}/>:"Delete"}
          </button>
          <button className='btn btn-outline-success btn-lg me-3 m-3' onClick={()=>props.action(false,false)}  disabled={props.actionLoader}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>  
        :
         <Modal backdrop="static" show={props.show} onHide={()=>props.action(false)} >
            <Modal.Header closeButton>
              <Modal.Title className='fs-2 fw-bold text-success p-1'>Status</Modal.Title>
            </Modal.Header>
            <Modal.Body className=' fs-5 fw-bold'>{props.message}</Modal.Body>
            <Modal.Footer>
              <button className='btn btn-outline-success btn-lg me-3 m-3' onClick={()=>props.action(false)}>
                Close
              </button>
            </Modal.Footer>
          </Modal>
      }
    </>  
  )
}

export default DialogModal