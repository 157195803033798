import React, { useEffect,useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Navbarp from '../Components/CommonComponents/Navbarp'
import SideNav from '../Components/CommonComponents/SideNav'
import { BASE_URL } from '../Config/Config';
import { logout } from '../features/Loginuser';
import { Navigate } from 'react-router-dom';
import '../css/List.css'
import Loader from '../Components/CommonComponents/Loader';
import DialogModal from '../Components/CommonComponents/DialogModal';
import SmallLoader from '../Components/CommonComponents/SmallLoader';

function AddPlanPage() {
    const token=useSelector((state) => state.loginuser.token);
    const Login = useSelector((state) => state.loginuser.Login);
    const [type,setType]=useState("")
    const [actionLoader,setActionLoader]=useState(false)
    let dispatch=useDispatch();
    const [show,setShow]=useState(false);
    const [message,setMessage]=useState("");
    const [loading,setLoading]=useState(false)
        function plandata(e){
            e.preventDefault();
            setLoading(true);
            const name_local={};
            name_local.en=e.target.name_local_en.value;
            name_local.hi=e.target.name_local_hi.value;
            var plandata =new FormData(e.target);
            // plandata.append("name",e.target.name.value)
            // plandata.append("description",e.target.description.value)
            plandata.append("name_local",JSON.stringify(name_local))
            fetch(BASE_URL+"addnewplan", {
                method: "POST",
                headers: {
                  //'X-XSRF-TOKEN': 'XSRF-TOKEN)',
                      'Accept': 'application/json',
                    "Authorization": "Bearer "+token,
                },
                body:plandata,
                
                })
                
                .then((response) => {
                  if (response.status===401){
                    dispatch(logout());
                  }else{
                    return response.json()
                  }
                })
                //Then with the data from the response in JSON...
                .then((data) => {
                    setType("status");
                    setShow(true);
                    setMessage(data.message);
                    setActionLoader(false)
                    setLoading(false)
                    e.target.reset();
                })
        }
function modelAction(show){
    setShow(show);
    }
if(Login==null||Login==false){
    return  <Navigate to="/" replace={true} />
    }    
  return (
     <>
      <DialogModal 
        show={show} 
        message={message} 
        action={modelAction} 
        id="" 
        type={type}
        actionLoader={actionLoader}
       />
      <div className="container-fluid ps-0 pe-0">
        <div className="row">
          <div>
           <Navbarp />
          </div>
          <div>
            <h3 className='ms-3'style={{'margin-top':'200px'}}>Plan Detail</h3>
                <form onSubmit={plandata}>   
                    <div className='mt-4'>
                        <div className="row align-items-start ">
                        <div className="ms-3 mb-3 col-12 col-lg-3 me-1">
                                <label  className="form-label">Plan Name</label>
                                <input type="text" name="name" className="form-control" required/>
                                <div id="emailHelp" className="form-text">Name of Plan.</div>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-3 me-1">
                                <label  className="form-label">Description</label>
                                <input type="text"  name="description" className="form-control" required />
                                <div className="form-text">Plan Description.</div>
                            </div>
                            
                            <div className="ms-3 mb-3 col-12 col-lg-3 me-1">
                                <label className="form-label">Priority</label>
                                <input type="number" name="priority" className="form-control" required  />
                                
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2 me-1">
                                <label  className="form-label">Is last</label>
                                <select className="form-select text-right" aria-label="Default select example" name="is_last" required>
                                      <option  value="">Select is Last</option>
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className="row align-items-start ">
                        <div className="ms-3 mb-3 col-12 col-lg-2 me-1">
                                <label className="form-label">Name English</label>
                                <input type="text" name="name_local_en" className="form-control"  required />
                                
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2 me-1">
                                <label className="form-label">Name Hindi</label>
                                <input type="text" name="name_local_hi" className="form-control" required />
                                
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2 me-1">
                                <label className="form-label">Is Trial</label>
                                <select className="form-select text-right" aria-label="Default select example" name="is_trial" required>
                                      <option  value="">Select is trial </option>
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                  </select>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2 me-1">
                                <label  className="form-label">Show on UI</label>
                                <select className="form-select text-right" aria-label="Default select example" name="show_on_ui" required>
                                      <option  value="">Select show on UI </option>
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                  </select>
                                
                            </div>
                           
                            <div className="ms-3 mb-3 col-12 col-lg-2 me-1">
                                <label  className="form-label">Show on Feature List</label>
                                <select className="form-select text-right" aria-label="Default select example" name="show_on_feature_list" required>
                                      <option  value="">Select show on feature list </option>
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                  </select>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-1 me-1">
                                <label  className="form-label">Active</label>
                                <select className="form-select text-right" aria-label="Default select example" name="is_active" required>
                                      <option  value="">Select status </option>
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                  </select>
                            </div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className="row align-items-start ">
                        <div className="ms-3 mb-3 col-12 col-lg-2 me-1">
                                <label  className="form-label">Daily Price</label>
                                <input type="number"  name="daily_price" className="form-control" required/>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2 me-1">
                                <label  className="form-label">Monthly Price</label>
                                <input type="number"  name="monthly_price" className="form-control" required/>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2 me-1">
                                <label  className="form-label">Quarterly Price</label>
                                <input type="number"  name="quarterly_price" className="form-control" required/>
                                <div className="form-text"></div>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2 me-1">
                                <label  className="form-label">Half Yearly Price</label>
                                <input type="number"  name="half_yearly_price" className="form-control" required />
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-3 me-1">
                                <label  className="form-label">Annually Price</label>
                                <input type="number"  name="annually_price" className="form-control" required />
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-warning mt-3 ms-3 btn-lg">
                    {loading?<SmallLoader loadingtext={"Submitting..."}/>:"Submit"}
                    </button>
                </form>    
          </div> 
        </div>
      </div>
  </>
  )
}

export default AddPlanPage