export function formatdate(type,value){
    if(isNaN(value)){
        var date =new  Date(value);
        if (type=="startdate"){
            date.setHours(5,30,0);//added offset 
            date.toUTCString();
            var startdate=parseInt(Date.parse(date) / 1000);
            return startdate;
        }
        if (type=="enddate"){
            date.setHours(29,29,59);//added offset 
            date.toUTCString();
            var enddate=parseInt(Date.parse(date) / 1000);
            return enddate;
        }
        if (type=="normal"){
            date.setHours(5,30,0);//added offset 
            date.toUTCString();
            var normaldate=parseInt(Date.parse(date) / 1000);
            return normaldate;
        }
        
    }else{
            return "";
        }

}
