import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import React from 'react'
import { Link } from "react-router-dom";
import LoginComponent from './LoginComponent'
import'../../css/navbar.css'
import { useSelector } from 'react-redux'
import Menubar from './SideNav';
export default function Navbarp() {
  const Login = useSelector((state) => state.loginuser.Login)
  return (
    <div className='main'>
      <Navbar bg="light" expand="lg" className='nav'>
      <Container fluid>
        <Navbar.Brand>
          <Nav.Link as={Link} to="/" >
            <img src="/logo_white_tagline.png" width="125px" className='text-center' />
          </Nav.Link>
        </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: '100px' }}
                navbarScroll
              >
              </Nav>

              {Login?
              <>
                <LoginComponent/>
              </>:
              null
              }
           
        </Navbar.Collapse>
      </Container>
  </Navbar>
  {Login?
    <>
      <Menubar/>
    </>
          :
    null
  }

</div>
  
  )
}
