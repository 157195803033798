import React from 'react'
import { Spinner } from 'react-bootstrap'
import '../../css/Loader.css'
function SmallLoader(props) {
  return (
            <div className='smallloader'>
              <Spinner  animation="border" role="status" />
              <span className='ms-2 mt-1'>{props.loadingtext}...</span>
            </div>
  )
}

export default SmallLoader