import React from 'react'
import {Navigate} from 'react-router-dom'
import Navbarp from '../Components/CommonComponents/Navbarp';
import SideNav from '../Components/CommonComponents/SideNav';
import {  Grid } from '@mui/material';
import { useState,useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import '.././css/Card.css'
import '.././css/Loader.css'
import'.././css/dashboard.css'
import '.././css/List.css'
import { DateTime } from 'luxon';
import { BASE_URL } from '../Config/Config';
import { logout } from '../features/Loginuser';
import { useDispatch, useSelector } from 'react-redux';
import Calendar from '../Components/CommonComponents/Calendar';
import DashboardList from '../Components/List/DashboardList';
import { AreaChart,Area, CartesianGrid, XAxis, YAxis,Tooltip, ResponsiveContainer } from 'recharts';


function Dashboard() {
  const Login = useSelector((state) => state.loginuser.Login);
  const token=useSelector((state) => state.loginuser.token);
  const [data,setData]=useState("");
  const dispatch=useDispatch();
  const [bigGraphData,setBigGraphData]=useState([])
  const [smallGraphData,setSmallGraphData]=useState([]);
  const [searchBy,setSearchBy]=useState({
    "startdate":DateTime.now().toUTC().startOf('day').toSeconds(),
    "enddate":DateTime.now().toUTC().endOf('day').toSeconds(),
    "monthStart":DateTime.now().toUTC().startOf('month').toSeconds(),
  });
    useEffect(() => {
     
      getdashboarddata();
    }, [searchBy])
  const searchstring=(searchBy)=>{
    let passdata={...searchBy};
    if(searchBy.startdate!=null && searchBy.enddate!=null){
      passdata.startdate=searchBy.startdate;
      passdata.enddate=searchBy.enddate;
    }
    
    var queryString = Object.keys(passdata).map(key => key + '=' + passdata[key]).join('&');
    return queryString
  }
  function getmax(Data,key){
    var max = Math.max(...Data.map(item => item[key]));
    return  parseInt(max)*10;
   
  }
  function getdashboarddata(){
    let searchstr=searchstring(searchBy);
      fetch(BASE_URL+"getdashboarddata?"+searchstr,{
          method: "GET",
          headers: {
            //'X-XSRF-TOKEN': 'XSRF-TOKEN)',
                'Accept': 'application/json',
              'Content-Type': 'application/json',
              "Authorization": "Bearer "+token,
          }

          })
          
          .then((response) => {
            if (response.status===401){
              dispatch(logout());
            }else{
              return response.json()
            }
          })
          //Then with the data from the response in JSON...
          .then((data) => {
            setData(data);
            setBigGraphData([...data.dashOrderDetail.dailyPayment])
            setSmallGraphData([...data.dashCstomerData.dailyCustomer])
          })

  }
    if(Login==null||Login==false){
      return  <Navigate to="/" replace={true} />
      }
  return (
        <div className="container-fluid ps-0 pe-0 ">
              <div className="row ps-0 pe-0">
                <Navbarp />
              </div>    
              <div>
                <div className='mt-1 overflow-auto'>
                  <Grid container className='ms-3 justify-content-between width_100 '>
                  <Grid item  className='ms-1 me-3'>
                                    <Calendar
                                      searchBy={searchBy} 
                                      setSearchBy={setSearchBy}
                                      type={"dashboard"}
                                    />
                            </Grid>
                          <div className=' ms-3 mt-2 d-md-flex w-100 justify-content-between  me-3'>
                          
                            <Grid item  className=' me-3 mt-2 '>
                              <Card  className='text-dark border border-dark '>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        New users
                                      </Typography>
                                      <Typography variant="h6" gutterBottom>
                                        {data?.dashCstomerData?.user}
                                      </Typography>
                                </CardContent>   
                              </Card>
                            </Grid>
                            <Grid item  className='me-3 mt-2'>
                              <Card className='text-secondary border border-secondary'>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        Dairy Owner
                                      </Typography>
                                      <Typography variant="h6" gutterBottom>
                                        {data?.dashCstomerData?.dairyUser}
                                      </Typography>
                                </CardContent>   
                              </Card>
                            </Grid>
                            <Grid item  className='me-3 mt-2'>
                              <Card className='text-success border border-success' >
                                <CardContent>
                                      <Typography variant="h6" gutterBottom>
                                        Kissan
                                      </Typography>
                                      <Typography variant="h6" gutterBottom>
                                        {data?.dashCstomerData?.kisanUser}
                                      </Typography>
                                </CardContent>   
                              </Card>
                            </Grid>
                            <Grid item  className='me-3 mt-2'>
                              <Card className='text-success border border-success'   >
                                <CardContent>
                                      <Typography variant="h6" gutterBottom>
                                        Subscription Active
                                      </Typography>
                                      <Typography variant="h6" gutterBottom>
                                        {data?.dashSubscription?.subsAct}
                                      </Typography>
                                </CardContent>   
                              </Card>
                            </Grid>
                            <Grid item  className='me-3 mt-2 '>
                              <Card className='text-warning border border-warning'  >
                                <CardContent>
                                      <Typography variant="h6" gutterBottom>
                                        Trial Active
                                      </Typography>
                                      <Typography variant="h6" gutterBottom>
                                      {data?.dashSubscription?.subsInTrial}
                                      </Typography>
                                </CardContent>   
                              </Card>
                            </Grid>
                            <Grid item  className='me-3 mt-2 text-danger'>
                              <Card className='text-danger border border-danger'  >
                                <CardContent>
                                      <Typography variant="h6" gutterBottom>
                                        Subscription Expiring 
                                      </Typography>
                                      <Typography variant="h6" gutterBottom>
                                        {data?.dashSubscription?.subsExp}
                                      </Typography>
                                </CardContent>   
                              </Card>
                            </Grid>
                            <Grid item  className='me-3 mt-2 text-danger'>
                              <Card className='text-danger border border-danger'  >
                                <CardContent>
                                      <Typography variant="h6" gutterBottom>
                                        Trial Expiring
                                      </Typography>
                                      <Typography variant="h6" gutterBottom>
                                      {data?.dashSubscription?.subsTrialEx}
                                      </Typography>
                                </CardContent>   
                              </Card>
                            </Grid>
                          
                          </div>
                  </Grid>
                  <Grid container className='ms-3 justify-content-between '>
                          <div className=' ms-3 d-md-flex  mt-2 width_100 me-3'>
                          <Grid item  className='me-3 mt-2 '>
                              <Card className='text-primary border-bottom border-5 border-primary ' >
                                <CardContent>
                                  <Typography variant="h6" gutterBottom>
                                    Payment Volume
                                  </Typography>
                                  <Typography variant="h6" gutterBottom>
                                    {parseInt(data?.dashOrderDetail?.orderPayment||0)}
                                  </Typography>
                                </CardContent>   
                              </Card>
                            </Grid>
                            <Grid item  className='me-3 mt-2 '>
                              <Card className='text-secondary border-bottom border-5 border-secondary'  >
                                <CardContent>
                                  <Typography variant="h6" gutterBottom>
                                    Total Orders
                                  </Typography>
                                  <Typography variant="h6" gutterBottom>
                                    {data?.dashOrderDetail?.totalOrder}
                                  </Typography>
                                </CardContent>   
                              </Card>
                            </Grid>
                            <Grid item  className='me-3 mt-2'>
                              <Card className='text-success border-bottom border-5 border-success'>
                                <CardContent>
                                  <Typography variant="h6" gutterBottom>
                                    Order Success
                                  </Typography>
                                  <Typography variant="h6" gutterBottom>
                                    {data?.dashOrderDetail?.orderPaid}
                                  </Typography>
                                </CardContent>   
                              </Card>
                            </Grid>
                            <Grid item  className='me-3 mt-2'>
                              <Card className='text-warning border-bottom border-5 border-warning'>
                                <CardContent>
                                  <Typography variant="h6" gutterBottom>
                                    Order Pending
                                  </Typography>
                                  <Typography variant="h6" gutterBottom>
                                    {data?.dashOrderDetail?.orderCreated}
                                  </Typography>
                                </CardContent>   
                              </Card>
                            </Grid>
                            
                            <Grid item  className='me-3 mt-2 text-danger'>
                              <Card  className='text-danger border-bottom border-5 border-danger'  >
                                <CardContent>
                                  <Typography variant="h6" gutterBottom>
                                    Order Failed
                                  </Typography>
                                  <Typography variant="h6" gutterBottom>
                                    {data?.dashOrderDetail?.orderFailed}
                                  </Typography>
                                </CardContent>   
                              </Card>
                            </Grid>
                          </div>
                  </Grid>
                  <Grid container className='justify-content-between'>
                          <div className='ms-3 me-3 d-md-flex  mt-2 width_100'>
                            <Grid item  className='me-3 mt-3'>
                                <div className='text-success fs-4 fw-bold ms-5'>Revenue</div>
                                <div className='mt-3 me-5'style={{ width: '100%', height: 400 }}>
                                  <ResponsiveContainer>
                                  <AreaChart width={875} height={300} data={bigGraphData}
                                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                      <defs>
                                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                          <stop offset="1%" stopColor="#8884d8" stopOpacity={0.5}/>
                                          <stop offset="99%" stopColor="#8884d8" stopOpacity={0.5}/>
                                        </linearGradient>
                                      </defs>
                                    <XAxis dataKey="dateCreated"  />
                                    <YAxis type="number" domain={[0, 'dataMax +' + getmax(bigGraphData,"Payments")]} tickCount={10}/>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <Tooltip />
                                    <Area type="monotone" dataKey="Payments" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)"/>
                                  
                                  </AreaChart>
                                  </ResponsiveContainer>
                                </div>
                              </Grid>
                              </div>
                  </Grid>
                </div> 
                <Grid container className='justify-content-between'>
                          <div className=' me-3 d-md-flex  mt-2 width_100'>
                            <Grid item  className='me-3'>
                                <div className='text-success fs-4 fw-bold ms-5'>Customer Trends</div>
                                <div className='mt-3 me-5'style={{ width: '100%', height: 400 }}>
                                  <ResponsiveContainer>
                                  <AreaChart width={875} height={300} data={smallGraphData}
                                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                      <defs>
                                      <linearGradient id="totalCus" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.5}/>
                                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.5}/>
                                      </linearGradient>
                                      <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#8884d8" stopOpacity={0.5}/>
                                        <stop offset="95%" stopColor="#8884d8" stopOpacity={0.5}/>
                                      </linearGradient>
                                      <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#8ba193" stopOpacity={0.5}/>
                                        <stop offset="95%" stopColor="#8ba193" stopOpacity={0.6}/>
                                      </linearGradient>
                              
                                      </defs>
                                      <XAxis dataKey="date_created" />
                                      <YAxis type="number" domain={[0, 'dataMax ']} tickCount={10}/>
                                      <CartesianGrid strokeDasharray="3 3" />
                                      <Tooltip />
                                      <Area type="monotone" dataKey="Total" stroke="#82ca9d" fillOpacity={1} fill="url(#totalCus)" />
                                      <Area type="monotone" dataKey="Kisaan" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                                      <Area type="monotone" dataKey="DairyOwner" stroke="#8ba193" fillOpacity={1} fill="url(#colorPv)" />
                                  </AreaChart>
                                  </ResponsiveContainer>
                                </div>
                              </Grid>
                              <Grid item className='me-3'>
                                <div className='text-success fs-4 fw-bold ms-3 mb-1 w-100'>Recent Orders</div>
                                <div  style={{ width: '100%', height: 400 }}>
                                  <DashboardList data={data?.dashOrderDetail?.recentOrder}/>
                                </div>
                              </Grid>
                            </div>
                </Grid>
             </div>
        </div>

    
    
    );
  }



export default Dashboard