import React, { useEffect,useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Navbarp from '../Components/CommonComponents/Navbarp'
import SideNav from '../Components/CommonComponents/SideNav'
import { BASE_URL } from '../Config/Config';
import { logout } from '../features/Loginuser';
import { Navigate} from 'react-router-dom';
import '../css/List.css'
import DialogModal from '../Components/CommonComponents/DialogModal';
import SmallLoader from '../Components/CommonComponents/SmallLoader';

function AddRateListPage() {
    const token=useSelector((state) => state.loginuser.token);
    const Login = useSelector((state) => state.loginuser.Login);
    const [type,setType]=useState("")
    let dispatch=useDispatch();
    const [show,setShow]=useState(false);
    const [actionLoader,setActionLoader]=useState(false)
    const [loading,setLoading]=useState(false)
    const [message,setMessage]=useState("");
        function createrateList(e){
            e.preventDefault();  
            setLoading(true);
            console.log(e.target.configuration.value)                 
            var rateListdata =new FormData(e.target);
            fetch(BASE_URL+"addnewpredefinedratelist", {
                method: "POST",
                headers: {
                  //'X-XSRF-TOKEN': 'XSRF-TOKEN)',
                      'Accept': 'application/json',
                    "Authorization": "Bearer "+token,
                },
                body:rateListdata,
                
                })
                
                .then((response) => {
                  if (response.status===401){
                    dispatch(logout());
                  }else{
                    return response.json()
                  }
                })
                //Then with the data from the response in JSON...
                .then((data) => {
                  setType("status");
                  setShow(true);
                  setMessage(data.message);
                  setActionLoader(false)
                  setLoading(false)
                  e.target.reset();
                })
        }
  function modelAction(show){
    setShow(show);
  }
  if(Login==null||Login==false){
    return  <Navigate to="/" replace={true} />
    }       
  return (
     <>
     
     <DialogModal 
        show={show} 
        message={message} 
        action={modelAction} 
        id="" 
        type={type}
        actionLoader={actionLoader}
       />
      <div className="container-fluid ps-0">
        <div className="row">
          <div>
           <Navbarp />
          </div>
          <div>
            <h3 className='mt-2' style={{'margin-top':'200px'}}>Add New RateList </h3>
                <form onSubmit={createrateList}>   
                    <div className='mt-4'>
                        <div className="row align-items-start ">
                        <div className="ms-3 mb-3 col-12 col-lg-3 me-1">
                                <label  className="form-label">Rate List Name</label>
                                <input type="text" name="name" className="form-control" required/>
                                <div id="emailHelp" className="form-text">Name of RateList</div>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-3 me-1">
                                <label  className="form-label">Description</label>
                                <input type="text"  name="description" className="form-control"  />
                                <div className="form-text">RateList Description.</div>
                            </div>
                            
                            <div className="ms-3 mb-3 col-12 col-lg-3 me-1">
                                <label className="form-label">Rate Chart type</label>
                                <select className="form-select text-right" aria-label="Default select example" name="rate_chart_setup_type" required>
                                      <option  value="">Type</option>
                                      <option value="general">General</option>
                                      <option value="cattle">Cattle</option>
                                 </select>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2 me-1">
                                <label  className="form-label">Rate chart type Cattle</label>
                                <select className="form-select text-right" aria-label="Default select example" name="rate_chart_setup_type_cattle" >
                                      <option  value="">Cattle</option>
                                      <option value="cow">Cow</option>
                                      <option value="buffalo">Buffalo</option>
                                 </select>
                            </div>
                           
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className="row align-items-start ">
                        <div className="ms-3 mb-3 col me-3">
                                <label  className="form-label">Configuration</label>
                                <textarea type="text"  name="configuration" className="form-control p-3" style={{height:"250px"}} required/>
                               
                            </div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className="row align-items-start ">
                        <div className="ms-3 mb-3 col-12 col-lg-6 me-1">
                                <label  className="form-label">Active</label>
                                <select className="form-select text-right" aria-label="Default select example" name="is_active"  defaultValue={1} required>
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                  </select>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-5 me-1">
                                <label  className="form-label">Deleted</label>
                                <select className="form-select text-right" aria-label="Default select example" name="is_deleted" defaultValue={0} required>
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                  </select>
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="btn ms-3 btn-warning btn-lg mt-3">
                    {loading?<SmallLoader loadingtext={"Submitting..."}/>:"Submit"}
                    </button>
                </form>    
          </div> 
        </div>
      </div>
  </>
  )
}

export default AddRateListPage