import React, { useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Select from 'react-select'
import { BASE_URL } from '../../Config/Config';
import { logout } from '../../features/Loginuser';

function CustomerDropDown(props){
  const [list,setList]=useState([]);
  const [searchBy,setSearchBy]=useState({
    records:50
  });
  let dispatch=useDispatch();
  const token=useSelector((state) => state.loginuser.token);
    useEffect(() => {
    getcustomerlist();
    
  }, [searchBy])

  function getcustomerlist(){
    let searchstr=searchstring(searchBy);
      fetch(BASE_URL+"customerlist?"+searchstr, {
          method: "GET",
          headers: {
            //'X-XSRF-TOKEN': 'XSRF-TOKEN)',
                'Accept': 'application/json',
              'Content-Type': 'application/json',
              "Authorization": "Bearer "+token,
          }

          })
          
          .then((response) => {
            if (response.status===401){
              dispatch(logout());
            }else{
              return response.json()
            }
          })
          //Then with the data from the response in JSON...
          .then((data) => {
              setList(data.result.data);
              
          })

  }
  function searchParam(value,key){
    setSearchBy({...searchBy,[key]:value})
   }
   const searchstring=(searchBy)=>{
     let passdata={...searchBy};
     if(searchBy.startdate!=null && searchBy.enddate!=null){
       passdata.startdate=searchBy.startdate;
       passdata.enddate=searchBy.enddate;
     }
     
     var queryString = Object.keys(passdata).map(key => key + '=' + passdata[key]).join('&');
     return queryString
   }

    const selectdata=list.map((data)=>{
      return {
      value:data.customer_id,
      label:data.firstname
      }
    })
   // console.log("selectdata",selectdata)
  
  return (
    <div style={{width: "100%"}}>
     
      <Select 
        isMulti
        options={selectdata}  
        onInputChange={(value)=>searchParam(value,"firstname")}
        onChange={(value)=>{searchParam(value.label,"firstname")
          const list =value.map((data)=>{return data.value
                     }) 
          props.onchange(list)
        }}
      />
    </div>
  )
}

export default CustomerDropDown