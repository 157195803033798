import React from 'react'
import  Form  from 'react-bootstrap/Form';
import Button  from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import { useState,useEffect } from 'react';
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import  {checkspecial,required,checkminlenght,checkuppercase,checknumber,checkemail,checkmaxlenght,checklowercase}  from '../Common/validation';
import Navbarp from '../Components/CommonComponents/Navbarp';
import SideNav from '../Components/CommonComponents/SideNav';
  function ProfilePage() {
    const Login = useSelector((state) => state.loginuser.Login)
    const userDetail=useSelector((state) => state.loginuser.userDetail)
  if(Login==null||Login==false){
    return  <Navigate to="/" replace={true} />
    }
  
    return (
      
        <div className="container-fluid ps-0 pe-0 ">
          <div className="row ps-0 pe-0">
            <Navbarp />
          </div>    
        <div>
        <h3 className='ms-2'style={{"margin-top":"215px"}}>Profile Page</h3>
          <div>
              <div>
                  <div > 
                    <Form className="m-2 " >
                      <Form.Group className="mb-3" controlId="formBasicName">
                          <Form.Label>Name</Form.Label>
                          <Form.Control type="text" defaultValue={userDetail.name} disabled/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicDate">
                          <Form.Label>Mobile</Form.Label>
                          <Form.Control type="text" defaultValue={userDetail.mobile} disabled/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Email address</Form.Label>
                          <Form.Control type="email" defaultValue={userDetail.email} disabled/>
                          <Form.Text className="text-muted">
                          </Form.Text>
                        </Form.Group>
                    </Form>
                </div>
            </div>
          </div>    
        </div>
      </div>
      
    );
  }
 export default ProfilePage