import { createSlice } from "@reduxjs/toolkit";
export const plan=createSlice({
    name :"plan",
    initialState:{
        plan:JSON.parse(localStorage.getItem('plan')),
    },
    reducers:{
        setplans:(state,action)=>{
            state.plan=action.payload;
            localStorage.setItem('plan',JSON.stringify(state.plan));
        }
    }

})
export const {setplans} =plan.actions;
export default plan.reducer;