import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { DateTime} from 'luxon';
import '../../css/List.css';
import TextField from '@mui/material/TextField';
import SortComponent from '../CommonComponents/SortComponent';
import { Link } from 'react-router-dom';

function OfferList(props) {
   const [value,setValue]=useState(null);
    return (
    <div className='mt-3 customer-list-table'>
        
     <Table striped bordered hover responsive>
         <thead>
            <tr className='tablehead'>
                <th>SNo</th>
                <th><SortComponent sort={props.sort} sortby={"offer_id"} />Offer Id</th>
                <th>Title Hindi </th>
                <th>Title English </th>
                <th><SortComponent sort={props.sort} sortby={"coupon_id"} />Coupon Attached </th>
                <th>Coupon Code</th>
                <th><SortComponent sort={props.sort} sortby={"start_date"} />Start Date</th>
                <th><SortComponent sort={props.sort} sortby={"end_date"} /> End Date</th>
                <th><SortComponent sort={props.sort} sortby={"is_active"} /> Active</th>
                <th><SortComponent sort={props.sort} sortby={"date_added"} />Created On</th>
                <th>Action</th>
            </tr>
            <tr className='text-center'>
                <th> </th>
                <th><input onChange={e=>props.searchParam(e.target.value,"offer_id")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"title")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"title")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"coupon_id")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"code")}/></th>
                <th><TextField type="date" className='text-center' onChange={e=>props.searchParam(e.target.value,"start_date")}/></th>
                <th><TextField type="date" className='text-center' onChange={e=>props.searchParam(e.target.value,"end_date")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"is_active")}/></th>
                <th><TextField type="date" className='text-center' onChange={e=>props.searchParam(e.target.value,"date_added")}/></th>
            </tr>
         </thead>
          <tbody>
            { 
                props.data.map((list,count) =>{
                    return( 
                    <tr className='text-center' key={list.coupon_id}>
                        <td>{++count}</td>
                        <td>{list.offer_id}</td>
                        <td>{list.title?.hi}</td>
                        <td>{list.title?.en}</td>
                        <td>{list.coupon_id}</td>
                        <td>{list.coupon?.code}</td>
                        <td>{list.start_date?DateTime.fromSQL(list.start_date).toFormat("dd-LLL-yyyy"):"--"}</td>
                        <td>{list.end_date?DateTime.fromSQL(list.end_date).toFormat("dd-LLL-yyyy"):"--"}</td>
                        <td>{list.is_active}</td>
                        <td>{list.date_added?DateTime.fromISO(list.date_added).toFormat("dd-LLL-yyyy"):"--"}</td>
                        <td><div style={{width: "150px"}}>
                            <Link to={`/offers/${list.offer_id}`} >
                                <button className='btn btn-outline-success' style={{width: "50px"}} >
                                        <i className="bi bi-pen me-1"/>
                                    </button>
                            </Link>
                            <button className='btn btn-outline-danger ms-2' style={{width: "50px"}} onClick={e=>props.returnoffer_id(list.offer_id)}  >
                                <i className="bi bi-trash3"></i>
                            </button>
                            </div>
                        </td>
                    </tr>)})
            }
        </tbody>
        </Table>
   
    
  </div>
  )
}

export default OfferList