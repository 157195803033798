import React, { useState} from 'react'
import  Form  from 'react-bootstrap/Form';
import Button  from 'react-bootstrap/Button';
import {required} from '../Common/validation'
import InputGroup from 'react-bootstrap/InputGroup';
import { useDispatch,useSelector } from 'react-redux';
import {Link, Navigate} from 'react-router-dom';
import '.././css/common.css' ;
import { BASE_URL } from '../Config/Config';
import Navbarp from '../Components/CommonComponents/Navbarp';
import { setTempToken } from '../features/Loginuser';
import { Spinner } from 'react-bootstrap';
import SmallLoader from '../Components/CommonComponents/SmallLoader';
import { useEffect } from 'react';

function Login() {
  const [Username,setUsername]=useState("")
  const [Password,setPassword]=useState("")
  const [Usernameerror,setUsernameerror]=useState("")
  const [PasswordErr,setPasswordErr]=useState("")
  const [passwordtype,setpasswordtype]=useState("password")
  const [Loginerror,setLoginerror]=useState("")
  const [Usernameinfo,setUsernameinfo]=useState(" We'll never share your Username with anyone else.")
  const [response,setResponse]=useState(false);
  const [loading,setLoading]=useState(false);
  const [attempt,setAttempt]=useState(null);
  const [attemptLeft,setAttemptLeft]=useState(null);
  const Login = useSelector((state) => state.loginuser.Login);
  const dispatch= useDispatch();
  function handleSubmit(e){
    e.preventDefault();
   
    // console.log("handle Submit")
    var passwal=validate(Password,"password")
    var Usernameval= validate(Username,"Username")
   
    if (passwal&&Usernameval){
      setLoading(true);
      var fdata =new FormData();
      fdata.append("Username",Username)
      fdata.append("Password",Password)
      
      fetch(BASE_URL+"login", {
        method: "POST",
        headers: {
               'Accept': 'application/json',
              
        },

        //make sure to serialize your JSON body
        body:fdata
        // JSON.stringify({
        //     Username: Username,
        //     Password: Password,
          
        // })
        })
        
        .then((response) => response.json())
        //Then with the data from the response in JSON...
        .then((data) => {
            if(data.status==true){
              setLoading(false)
              setResponse(data.status);
              dispatch(setTempToken(data.tempToken));
              setUsername("");
              setPassword("");
            }else{
              setLoading(false)
              setLoginerror(data.message)  
              setAttempt(data.Attempts);
              setAttemptLeft(data.Attempts_Left)
            }
        });
      }
  }
    function validate(data,type){
    switch(type){
      case "Username":
      if (required(data)){
        setUsernameerror("Username cannot be blank")
        
        return false;
      }
      // else if(!checkUsername(data)) {
      //   setUsernameerror("Please Enter a valid Username")
        
      //   return false;
      // }
      else{
        setUsernameerror(null)
        setUsername(data)
      
      }
      break;
      case "password":
        if(required(data)){
          setPasswordErr("Password cannot be left blank ")
          
          return false;
        }else{
        setPasswordErr(null)
        setPassword(data);
        }
        break;

    }
    return true;
  }
    function setPasswordtype(){
 
      if(passwordtype=="password")
      { 
        setpasswordtype("text")
      }else {
        setpasswordtype("password")
      }  
    }
    if(response==true){
    return  <Navigate to="/otpverification" replace={true} />
    }
    if(Login==true){
      return <Navigate to="/dashboard" replace={true} />
      }
  return (
    <div className='container'>
      
      <Navbarp/>
      <div style={{"marginTop":"150px"}}>
      <h1 className='text-center fs-1 m-5 p-3  fw-bold'>Welcome to Liter Admin Panel</h1>
      <p  className='m-3 fs-3 fw-bold m-1 text-center'>Please Enter Credential for Login</p>
      <div class="d-flex w-100 flex-row justify-content-center">
        <div class="d-flex w-100 flex-column justify-content-center" style={{"max-width":"500px"}}>
          <div>
            <Form className="m-2" onSubmit={handleSubmit} >
            <Form.Group className="mb-3 p-1"  controlId="formBasic">
              <Form.Label className="fs-5 fw-bold m-1">
              <i className="bi bi-person  me-2"></i>
                Username or Email
              </Form.Label>
              <Form.Control type="text" onChange={e=>{validate(e.target.value,"Username")}} onBlur={ e=>{validate(e.target.value,"Username")}} placeholder="Please Enter Username or Email " />
              {Usernameerror ? <span className='text-danger m-1'>{Usernameerror}</span>: <span className='text-muted m-1'>{Usernameinfo}</span>}<br/>
            </Form.Group>
            <Form.Group className="mt-3" controlId="formBasicPassword">
              <Form.Label className="fs-5 fw-bold m-1">
              <i className="bi bi-key me-2"></i>
                Password
              </Form.Label>
              <InputGroup>
              <Form.Control type={passwordtype} onChange={e=>{validate(e.target.value,"password")}}  onBlur={ e=>{validate(e.target.value,"password")}} placeholder="Please Enter Your  Password" />
              <Button variant="outline-primary" onClick={setPasswordtype}>
              { passwordtype==="password"? <i className="bi bi-eye-slash"></i> :<i className="bi bi-eye"></i> }
              </Button>
              </InputGroup>
              <span><Link  to={"forgotpassword"}>Forgot Password</Link></span><br/> 
              {PasswordErr ? <span className='text-danger'>{PasswordErr}</span>: null  }<br/>
              {Loginerror ? <p className='text-danger fs-5 fw-bold mt-1 text-center'>{Loginerror}</p>: null}
              {attemptLeft?
              <div className='d-flex justify-content-between text-danger fs-6 fw-bold'>
                <span>Attempt-{attempt}</span>
                <span>Attempts Left-{attemptLeft}</span>
              </div>:null}
            </Form.Group>
            <div className="d-grid gap-2">
              <Button variant="warning" style={{Color:'white'}} size="lg" type='submit' disabled={loading}>
                {loading?<SmallLoader loadingtext={"Verifying"}/>:"Login"}
              
              </Button>
            </div>
          </Form>
        </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Login