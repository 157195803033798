import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import Navbarp from '../Components/CommonComponents/Navbarp';
import SideNav from '../Components/CommonComponents/SideNav';
import { TextField, Grid } from '@mui/material';
import { useState,useEffect } from 'react';
import PagesNumber from '../Components/CommonComponents/PagesNumber';
import {BASE_URL} from '../Config/Config';
import { logout } from '../features/Loginuser';
import { setplans } from '../features/Plan';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Form from 'react-bootstrap/Form';
import '.././css/List.css'
import '.././css/Card.css'
import '.././css/Loader.css'
import { formatdate } from '../Common/Dateformat';
import PredefinedRateList from '.././Components/List/PredefinedRateList';
import Loader from '../Components/CommonComponents/Loader';
import Calendar from '../Components/CommonComponents/Calendar';
import DialogModal from '../Components/CommonComponents/DialogModal';

function GeneralRateList() {
  const dispatch=useDispatch();
  const [startdate, setstartdate] =useState("");
  const[enddate,setenddate]=useState("");
  const [list, setList] = useState([]);
  const Login = useSelector((state) => state.loginuser.Login);
  const token=useSelector((state) => state.loginuser.token);
  const [lastPage, setLastPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading,setLoading]=useState(true)
  const [totalratelist,setTotalRateList]=useState(0) 
  const [count, setCount] = useState(0);
  const [sortingby,setSortingBy]=useState(1)
  const [rate_list_id,setRate_list_id]=useState(null)
  const [show,setShow]=useState(false);
  const [message,setMessage]=useState("");
  const [type,setType]=useState("")
  const [actionLoader,setActionLoader]=useState(false)
  const [firstLoading,setFirstLoading]=useState(true)
  const [searchBy,setSearchBy]=useState({
      "records":100,
      "startdate":"",
      "enddate":""
    });
  useEffect(() => {
    if(firstLoading){
      getpredefinedratelist();
      setFirstLoading(false)
    }else{
      const getData = setTimeout(() => {
        getpredefinedratelist();
      }, 500)
      return () => clearTimeout(getData)
    }
    getpredefinedratelist()
  }, [currentPage,searchBy])
  function getpredefinedratelist(){
    let searchstr=searchstring(searchBy);
      fetch(BASE_URL+"dairyratelistpreset?page="+currentPage+"&"+searchstr, {
          method: "GET",
          headers: {
            //'X-XSRF-TOKEN': 'XSRF-TOKEN)',
                'Accept': 'application/json',
              'Content-Type': 'application/json',
              "Authorization": "Bearer "+token,
          }

          })
          
          .then((response) => {
            if (response.status===401){
              dispatch(logout());
            }else{
              return response.json()
            }
          })
          //Then with the data from the response in JSON...
          .then((data) => {
              setLoading(false);
              setList(data.result.data);
              setLastPage(data.result.last_page);
              setCurrentPage(data.result.current_page);
              setTotalRateList(data.totalratelist);
              setCount(data.count)
              
          })
  }
    function setpage(value){
      if (value >=1&& value<=lastPage){
          setCurrentPage(value)
      }
    }
    function searchParam(value,key){
     setSearchBy({...searchBy,[key]:value})
    }
    const searchstring=(searchBy)=>{
      let passdata={...searchBy};
      if(searchBy.startdate!=null && searchBy.enddate!=null){
        passdata.startdate=searchBy.startdate;
        passdata.enddate=searchBy.enddate;
      }
      var queryString = Object.keys(passdata).map(key => key + '=' + passdata[key]).join('&');
      return queryString
    }
    var arr=[...list]
    function sort(type,key){
      if(sortingby==1){
        //code for sorting on server 
        setSearchBy({...searchBy,"sortby":key,"sorttype":type});
      }else{
      // Code for sorting in browser 
        if (type=="asc"){
         for (let i=0;i<arr.length;i++){
             for (let j=0;j<=i;j++){
                 if (arr[i][key] < arr[j][key]) {
                     var x = arr[i];
                     arr[i] =arr[j];
                     arr[j] = x;
                 }
             }
         }
      }
      if (type=="desc"){
         for (let i=0;i<arr.length;i++){
             for (let j=0;j<=i;j++){
                 if (arr[i][key] >arr[j][key]) {
                     var x = arr[i];
                     arr[i] =arr[j];
                     arr[j] = x;
                 }
             }
         }
      }
     setList(arr);
      }
     }
     function returnratelist_id(rate_list_id){
      setType("confirm")
      setMessage("Are you sure you want to delete the ratelsit")
      setShow(true);
      setRate_list_id(rate_list_id);
      console.log("in get customer_id",rate_list_id)
    }
    function modelAction(show,descision,rate_list_id){
      setShow(show);
      if(descision){
        setActionLoader(true);
        deleteratelist(rate_list_id);
      }else{
        setRate_list_id(null);
      }
    }
    function deleteratelist(rate_list_id){
      var fdata =new FormData();
      fdata.append("rate_list_id",rate_list_id)
        fetch(BASE_URL+"deletepredefinedratelist", {
          method: "POST",
          headers: {
            //'X-XSRF-TOKEN': 'XSRF-TOKEN)',
                'Accept': 'application/json',
              "Authorization": "Bearer "+token,
          },
          body:fdata,
          })
          
          .then((response) => {
            if (response.status===401){
              dispatch(logout());
            }else{
              return response.json()
            }
          })
          //Then with the data from the response in JSON...
          .then((data) => {
            setType("status")
            setShow(true)
            setMessage(data.message)
            setActionLoader(false)
          })
          getpredefinedratelist()
    }
    if(Login==null||Login==false){
    return  <Navigate to="/" replace={true} />
    }
     return (
       <>
       <DialogModal 
        show={show} 
        message={message} 
        action={modelAction} 
        id={rate_list_id} 
        type={type}
        actionLoader={actionLoader}
       />
         <div className="container-fluid ps-0 pe-0">
           <div className="row">
             <div>
             <Navbarp />
             </div>
             <div>
                <div className='mt-1'>
                <Grid container className='justify-content-between ms-3' >
                  <div className='d-md-flex width_100 me-3'>
                        <Grid item  className='me-3 mt-2'>
                          <Card className='record' >
                            <CardContent>
                              <Typography variant="h6" gutterBottom>
                                 Record Found
                              </Typography>
                               <Typography variant="h6" gutterBottom>
                                 {count}
                              </Typography>
                            </CardContent>   
                          </Card>
                        </Grid>
                        <Grid item  className='me-3 mt-2'>
                          <Card className='total'>
                            <CardContent>
                              <Typography variant="h6" gutterBottom>
                                 Total RateList
                              </Typography>
                              <Typography variant="h6" gutterBottom>
                                 {totalratelist}
                              </Typography>
                            </CardContent>   
                          </Card>
                        </Grid>
                  </div>      
                  <div>
                    <Grid container className='justify-content-between' >
                                   <Link to="addratelist" replace={true} >
                                      <button type="button" className="btn btn-success mt-4 mb-2 ms-2">
                                        <i className="bi bi-plus-lg me-1"/>
                                          Add Rate List
                                      </button>
                                    </Link>
                                   <Calendar
                                      searchBy={searchBy} 
                                      setSearchBy={setSearchBy}
                                      setSortingBy={setSortingBy}
                                      lastPage={lastPage} 
                                      currentPage={currentPage} 
                                      setpage={setpage} 
                                    />
                          </Grid>
                  </div>
                </Grid>
                  {loading?
                   <Loader/>:
                  <div>
                  <PredefinedRateList data={list} searchParam={searchParam} sort={sort} returnratelist_id={returnratelist_id}/>
                  </div>
                  }
                  
                </div>       
             </div>
           </div>
         </div>
       </>
     );
}

export default GeneralRateList