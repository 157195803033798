import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { DateTime} from 'luxon';
import '../../css/List.css';
import TextField from '@mui/material/TextField';
import SortComponent from '../CommonComponents/SortComponent';



function PaymentList(props) {
   const [value,setValue]=useState(null);
    return (
    <div className='mt-3 customer-list-table'>
        
     <Table striped bordered hover responsive>
         <thead>
            <tr className='tablehead'>
                <th>SNo</th>
                <th>integration Order Id<SortComponent sort={props.sort} sortby={"integration_order_id"} /></th>
                <th>Integration Payment Id<SortComponent sort={props.sort} sortby={"integration_payment_id"} /></th>
                <th>Order Id <SortComponent sort={props.sort} sortby={"order_id"} /></th>
                <th>Created At<SortComponent sort={props.sort} sortby={"date_added"} /></th>
                <th>Customer Id <SortComponent sort={props.sort} sortby={"customer_id"} /></th>
                <th><SortComponent sort={props.sort} sortby={"firstname"} />Customer Name </th>
                <th><SortComponent sort={props.sort} sortby={"telephone"} />Mobile </th>
                <th><SortComponent sort={props.sort} sortby={"email"} />Email </th>
                <th>Payment Status</th>
                <th>Attempts<SortComponent sort={props.sort} sortby={"attempts"} /></th>
                <th>Amount<SortComponent sort={props.sort} sortby={"amount"} /></th>
                <th>Payment Method<SortComponent sort={props.sort} sortby={"method"} /></th>
                <th>VPA<SortComponent sort={props.sort} sortby={"vpa"} /></th>
                <th>Card Network<SortComponent sort={props.sort} sortby={"network"} /></th>
                <th>Card Issuer<SortComponent sort={props.sort} sortby={"issuer"} /></th>
                <th>Card Last4 <SortComponent sort={props.sort} sortby={"last4"} /></th>
                <th>Bank<SortComponent sort={props.sort} sortby={"bank"} /></th>
                <th>Wallet<SortComponent sort={props.sort} sortby={"wallet"} /></th>
                <th>Error <SortComponent sort={props.sort} sortby={"error_code"} /></th>
                <th>Error Description<SortComponent sort={props.sort} sortby={"error_description"} /></th>
                <th>Error Source<SortComponent sort={props.sort} sortby={"error_source"} /></th>
            </tr>
            <tr className='text-center'>
                <th> </th>
                <th><input onChange={e=>props.searchParam(e.target.value,"integration_order_id")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"integration_payment_id")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"order_id")}/></th>
                <th><TextField type="date" className='text-center' onChange={e=>props.searchParam(e.target.value,"date_added")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"customer_id")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"customer_name")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"telephone")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"email")}/></th>
                <th>
                    <select className="form-select text-center" aria-label="Default select example" style={{width: "170px"}} onChange={e=>props.searchParam(e.target.value,"payment_status")}>
                            <option value="">Select status</option>
                            <option value="captured">Captured</option>
                            <option value="failed">Failed</option>
                        </select>
                </th>
                <th><input onChange={e=>props.searchParam(e.target.value,"attempts")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"amount")}/></th>
                <th>
                    <select className="form-select text-center" aria-label="Default select example" style={{width: "170px"}} onChange={e=>props.searchParam(e.target.value,"method")}>
                                <option value="">Select Method</option>
                                <option value="upi">UPI</option>
                                <option value="netbanking">Net Banking</option>
                                <option value="card">Card</option>
                                <option value="wallet">Wallet</option>
                     </select>
                </th>
                <th><input onChange={e=>props.searchParam(e.target.value,"vpa")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"network")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"issuer")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"last4")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"bank")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"wallet")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"error_code")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"error_description")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"error_source")}/></th>
            </tr>
         </thead>
          <tbody>
            { 
                props.data.map((list,count) =>{
                    return( 
                    <tr className='text-center' key={list.order_id}>
                        <td>{++count}</td>
                        <td>{list.integration_order_id?list.integration_order_id:"---"}</td>
                        <td>{list.integration_payment_id?list.integration_payment_id:"---"}</td>  
                        <td>{list.order_id}</td>
                        <td>{list.integration_payment_response?.created_at?DateTime.fromSeconds(list.integration_payment_response.created_at).toFormat("dd-LLL-yyyy"):"--"}</td>
                        <td>{list.customer_id}</td>
                        <td>{(list.customer?.firstname||list.customer?.lastname)? list.customer.firstname+" "+list.customer.lastname:"---"}</td>
                        <td>{(list.customer?.telephone)? list.customer.telephone:"---"}</td>
                        <td>{list.integration_payment_response?.email?list.integration_payment_response?.email:"---"}</td>
                        <td>{list.payment_status}</td>
                        <td>{list.integration_order_response?.attempts?list.integration_order_response?.attempts:"---"}</td>
                        <td>{list.integration_payment_response?.amount?list.integration_payment_response?.amount/100:"---"}</td>
                        <td>{list.integration_payment_response?.method?list.integration_payment_response?.method:"---"}</td>
                        <td>{list.integration_payment_response?.vpa?list.integration_payment_response?.vpa:"---"}</td>
                        <td>{list.integration_payment_response?.card?.network?list.integration_payment_response?.card?.network:"---"}</td>
                        <td>{list.integration_payment_response?.card?.issuer?list.integration_payment_response?.card?.issuer:"---"}</td>
                        <td>{list.integration_payment_response?.card?.last4?list.integration_payment_response?.card?.last4:"---"}</td>
                        <td>{list.integration_payment_response?.bank?list.integration_payment_response?.bank:"---"}</td>
                        <td>{list.integration_payment_response?.wallet?list.integration_payment_response?.wallet:"---"}</td>
                        <td>{list.integration_payment_response?.error_code?list.integration_payment_response?.error_code:"---"}</td>
                        <td>{list.integration_payment_response?.error_description?list.integration_payment_response?.error_description:"---"}</td>
                        <td>{list.integration_payment_response?.error_source?list.integration_payment_response?.error_source:"---"}</td>
                    </tr>)})
            }
        </tbody>
        </Table>
   
    
  </div>
  )
}

export default PaymentList