import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { DateTime} from 'luxon';
import '../../css/List.css';
import TextField from '@mui/material/TextField';
import SortComponent from '../CommonComponents/SortComponent';
import DurationDropDown from '../Dropdown/DurationDropDown';
import CouponDropDown from '../Dropdown/CouponDropDown';
import PlanPlanDropDown from '../Dropdown/PlanDropDown';

function OrderList(props) {
   const [value,setValue]=useState(null);
    return (
    <div className='mt-3 customer-list-table'>
        
     <Table striped bordered hover responsive>
         <thead>
            <tr className='tablehead'>
                <th>SNo</th>
                <th>Order Id <SortComponent sort={props.sort} sortby={"order_id"} /></th>
                <th>Date Added <SortComponent sort={props.sort} sortby={"date_added"} /></th>
                <th>Customer Id <SortComponent sort={props.sort} sortby={"customer_id"} /></th>
                <th><SortComponent sort={props.sort} sortby={"firstname"} />Customer Name </th>
                <th><SortComponent sort={props.sort} sortby={"telephone"} />Mobile </th>
                <th>Plan Name  </th>
                <th>Coupon Name </th>
                <th style={{width:"300px"}}>Coupon Code</th>
                <th>Referral<SortComponent sort={props.sort} sortby={"referral_code_id"} /></th>
                <th>Duration<SortComponent sort={props.sort} sortby={"duration"} /></th>
                <th>Renew<SortComponent sort={props.sort} sortby={"renew"} /></th>
                <th>Price <SortComponent sort={props.sort} sortby={"price"} /></th>
                <th>Previous Balance<SortComponent sort={props.sort} sortby={"previous_balance"} /></th>
                <th>Sub Total<SortComponent sort={props.sort} sortby={"sub_total"} /></th>
                <th>Discount<SortComponent sort={props.sort} sortby={"discount"} /></th>
                <th>Net Amount <SortComponent sort={props.sort} sortby={"net_amount"} /></th>
                <th>Total<SortComponent sort={props.sort} sortby={"total"} /></th>
                <th>Tax<SortComponent sort={props.sort} sortby={"tax"} /></th>
                <th>Remaining_balance<SortComponent sort={props.sort} sortby={"remaining_balance"} /></th>
                <th>Payment Status<SortComponent sort={props.sort} sortby={"payment_status"} /></th>
                <th>integration Order Id<SortComponent sort={props.sort} sortby={"integration_order_id"} /></th>
                <th>Integration Payment Id<SortComponent sort={props.sort} sortby={"integration_payment_id"} /></th>
                <th>Integration Type<SortComponent sort={props.sort} sortby={"integration_type"} /></th>
                <th>Order Status<SortComponent sort={props.sort} sortby={"order_status"} /></th>
                <th>Polling Iteration<SortComponent sort={props.sort} sortby={"polling_iteration"} /></th>
                <th>Remark<SortComponent sort={props.sort} sortby={"remark"} /></th>
                <th>Date Updated<SortComponent sort={props.sort} sortby={"date_updated"} /></th>
            </tr>
            <tr className='text-center'>
                <th> </th>
                <th><input onChange={e=>props.searchParam(e.target.value,"order_id")}/></th>
                <th><TextField type="date" className='text-center' onChange={e=>props.searchParam(e.target.value,"date_added")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"customer_id")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"customer_name")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"telephone")}/></th>
                <th><div style={{width: "150px"}}>
                    <PlanPlanDropDown searchParam={props.searchParam}/>
                    </div>
                </th>
                <th><div style={{width: "250px"}}>
                    <CouponDropDown searchParam={props.searchParam} type={"coupon_name"} column={"name"} />
                    </div>
                </th>
                <th><div style={{width: "200px"}}>
                    <CouponDropDown searchParam={props.searchParam} type={"coupon_code"} column={"code"}/>
                    </div>
                </th>
                <th><input onChange={e=>props.searchParam(e.target.value,"referral_code_id")}/></th>
                <th>
                    <div style={{width: "150px"}}>
                    <DurationDropDown searchParam={props.searchParam}></DurationDropDown>                   
                   </div>
                </th>
                <th><input onChange={e=>props.searchParam(e.target.value,"renew")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"price")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"previous_balance")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"sub_total")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"discount")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"net_amount")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"total")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"tax")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"remaining_balance")}/></th>
                <th>
                     <select className="form-select text-center" aria-label="Default select example" style={{width: "170px"}} onChange={e=>props.searchParam(e.target.value,"payment_status")}>
                            <option value="">Select status</option>
                            <option value="captured">Captured</option>
                            <option value="failed">Failed</option>
                     </select>
                </th>
                <th><input onChange={e=>props.searchParam(e.target.value,"integration_order_id")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"integration_payment_id")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"integration_type")}/></th>
                <th>
                <select className="form-select text-center" aria-label="Default select example" style={{width: "170px"}} onChange={e=>props.searchParam(e.target.value,"order_status")}>
                            <option value="">Select status</option>
                            <option value="created">Created</option>
                            <option value="paid">Paid</option>
                            <option value="attempted">Attempted</option>
                     </select>
                </th>

                <th><input onChange={e=>props.searchParam(e.target.value,"polling_iteration")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"remark")}/></th>
                <th><TextField type="date" className='text-center' onChange={e=>props.searchParam(e.target.value,"date_updated")}/></th>

            </tr>
         </thead>
          <tbody>
            { 
                props.data.map((list,count) =>{
                    return( 
                    <tr className='text-center' key={list.order_id}>
                    <td>{++count}</td>
                    <td>{list.order_id}</td>
                    <td>{list.date_added?DateTime.fromSQL(list.date_added).toFormat("dd-LLL-yyyy"):"--"}</td>
                    <td>{list.customer_id}</td>
                    <td>{(list.customer?.firstname||list.customer?.lastname)? list.customer.firstname+" "+list.customer.lastname:"---"}</td>
                    <td>{(list.customer?.telephone)? list.customer.telephone:"---"}</td>
                    <td>{list.plan?.name}</td>
                    <td>{list.coupon?.name}</td>
                    <td>{list.coupon?.code}</td>
                    <td>{list.referral_code_id}</td>
                    <td>{list.duration+" Month"}</td>
                    <td>{list.renew}</td>
                    <td>{list.price}</td>
                    <td>{list.previous_balance}</td>
                    <td>{list.sub_total}</td>
                    <td>{list.discount}</td>
                    <td>{list.net_amount}</td>
                    <td>{list.total}</td>
                    <td>{list.tax}</td>
                    <td>{list.remaining_balance}</td>
                    <td>{list.payment_status}</td>
                    <td>{list.integration_order_id}</td>
                    <td>{list.integration_payment_id}</td>
                    <td>{list.integration_type}</td>
                    <td>{list.order_status}</td>
                    <td>{list.polling_iteration}</td>
                    <td>{list.remark}</td>
                    <td>{list.date_updated?DateTime.fromSQL(list.date_updated).toFormat("dd-LLL-yyyy"):"--"}</td>
                    </tr>)})
            }
        </tbody>
        </Table>
   
    
  </div>
  )
}

export default OrderList