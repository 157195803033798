import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';

function CustomerLogin() {
  const Login = useSelector((state) => state.loginuser.Login);
  const token=useSelector((state) => state.loginuser.token);
  let params = useParams();
    if(Login==null||Login==false){
    return  <Navigate to="/" replace={true} />
    }
     return (
       <>
       <h1>Customer Login {params.customer_id}</h1>
       </>
     );
}

export default CustomerLogin