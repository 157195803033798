import React, { useState } from 'react'
import '../../css/PagesNumber.css'
import Pagination  from 'react-bootstrap/Pagination';
function PagesNumber(props) {
         
  return (
    <div>
    <Pagination size='md' >
    <Pagination.First  onClick={()=>props.setpage(1)}/>
    <Pagination.Prev onClick={()=>props.setpage(props.currentPage-1)} />
    <Pagination.Item>
        <input className='ms-1 me-1 fw-bold text-center width60' value={props.currentPage} onChange={(e)=>props.setpage(e.target.value)}></input>
        </Pagination.Item>
    <Pagination.Next  onClick={()=>props.setpage(props.currentPage+1)}/>
    <Pagination.Last onClick={()=>props.setpage(props.lastPage)}/>
    </Pagination>
    </div>
  
  )
}

export default PagesNumber