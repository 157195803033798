import React from 'react'
import { Spinner } from 'react-bootstrap'
import '../../css/Loader.css'
function Loader() {
  return (
    <div className='position'>
                    <Spinner  animation="border" role="status" />
                    <span className='loading'>Loading...</span>
                  </div>
  )
}

export default Loader