import React, { useState } from 'react';
import '../../css/SortComponent.css';


export default function SortComponent(props) {
  const [filterUp,setFilterUp]=useState(false);
  
  return (
  <>
   { filterUp ?
    <button className="btn btn-outline-light filterwidht" onClick={()=>{setFilterUp(false); props.sort("desc",props.sortby) }}>
    <i className="bi bi-sort-down-alt"></i>
  </button>:
  <button className="btn btn-outline-light filterwidht " onClick={()=>{setFilterUp(true); props.sort("asc",props.sortby) }}>
      <i className="bi bi-sort-down"></i>
    </button>
 }
 </>
  )
}
