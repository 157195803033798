import React, { useState} from 'react'
import  Form  from 'react-bootstrap/Form';
import Button  from 'react-bootstrap/Button';
import {checkemail, required} from '../Common/validation'
import InputGroup from 'react-bootstrap/InputGroup';
import { useDispatch,useSelector } from 'react-redux';
import {Navigate} from 'react-router-dom';
import '.././css/common.css' ;
import { BASE_URL } from '../Config/Config';
import Navbarp from '../Components/CommonComponents/Navbarp';
import { setTempToken } from '../features/Loginuser';
import { Spinner } from 'react-bootstrap';
import SmallLoader from '../Components/CommonComponents/SmallLoader';
import { useEffect } from 'react';

function Forgotpassword() {
  const [Username,setUsername]=useState("")
  const [email,setEmail]=useState("")
  const [Usernameerror,setUsernameerror]=useState("")
  const [response,setResponse]=useState(false);
  const [loading,setLoading]=useState(false);
  const [emailerror,setEmailerror]=useState("")
  const [message,setMessage]=useState("")
  function handleSubmit(e){
    e.preventDefault();
   
    // console.log("handle Submit")
    var emailval=validate(email,"email")
    var Usernameval= validate(Username,"Username")
   
    if (emailval&&Usernameval){
      setLoading(true);
      var fdata =new FormData();
      fdata.append("username",Username)
      fdata.append("email",email)
      
      fetch(BASE_URL+"forgotpassword", {
        method: "POST",
        headers: {
               'Accept': 'application/json',
              
        },

        //make sure to serialize your JSON body
        body:fdata
        // JSON.stringify({
        //     Username: Username,
        //     Password: Password,
          
        // })
        })
        
        .then((response) => response.json())
        //Then with the data from the response in JSON...
        .then((data) => {
            
              setLoading(false);
              setMessage(data.message);
              setEmail("");
              setUsername("");
              e.target.reset()
            
            
        });
      }
  }
    function validate(data,type){
    switch(type){
      case "Username":
      if (required(data)){
        setUsernameerror("Username cannot be blank")
        
        return false;
      }
      else{
        setUsernameerror(null)
        setUsername(data)
      
      }
      break;
      case "email":
      if (required(data)){
        setEmailerror("Email cannot be blank")
        return false;
      }
      else if(!checkemail(data)) {
        setEmailerror("Please Enter a valid email")
        return false;
      }
      else{
        setEmailerror(null)
        setEmail(data)
      
      }
      break;
    }
    return true;
  }
   
    if(response==true){
    return  <Navigate to="/login" replace={true} />
    }
  return (
    <div>
      
      <Navbarp/>
    
      <h1 className='text-center fs-1 m-5 p-3  fw-bold'>Reset Password</h1>
      <p  className='m-3 fs-3 fw-bold m-1 text-center'>Please verify your username and email</p>
      <div class="d-flex w-100 flex-row justify-content-center">
        <div class="d-flex m-2 w-100 flex-column" style={{"max-width":"500px"}}>
          <div>
            <Form className="p-1" onSubmit={handleSubmit} >
              <Form.Group className="mt-3" controlId="formBasic">
                <Form.Label className="fs-5 fw-bold m-1">
                <i className="bi bi-person  me-2"></i>
                  Username
                </Form.Label>
                <Form.Control type="text" onChange={e=>{validate(e.target.value,"Username")}} onBlur={ e=>{validate(e.target.value,"Username")}} placeholder="Please Enter Username of Login " />
                {Usernameerror ? <span className='text-danger m-1'>{Usernameerror}</span>: null}<br/>
              </Form.Group>
              <Form.Group className="mt-3" controlId="formBasic">
                <Form.Label className="fs-5 fw-bold m-1">
                <i className="bi bi-envelope me-2"></i>
                  Email
                </Form.Label>
                <Form.Control type="email" onChange={e=>{validate(e.target.value,"email")}} onBlur={ e=>{validate(e.target.value,"email")}} placeholder="Please Enter Email " />
                {emailerror ? <span className='text-danger m-1'>{emailerror}</span>: null}<br/>
              </Form.Group>
              <div className="d-grid gap-2">
                {message ? <span className='text-danger text-center fs-5 fw-bold m-1'>{message}</span>: null}<br/>
                  <Button variant="warning" style={{Color:'white'}} size="lg" type='submit' disabled={loading}>
                    {loading?<SmallLoader loadingtext={"Sending"}/>:"Send Reset Link"}
                  </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Forgotpassword