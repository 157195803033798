import { DateTime, Duration } from "luxon";

export function DateDifference(date){
    var diff=DateTime.now().diff(DateTime.fromSQL(date),['months', 'days', 'hours','minutes']).toObject();
    let value=null;
    Object.keys(diff).map((key) => {
        if (value!=null) {
            return value;
        }
        if (diff[key]!=0){
            value= (parseInt(diff[key]))+" "+key+" ago"
            return value;
        }
    }
    )
   // console.log(value);
    return value;
   
}