import React, { useEffect,useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Navbarp from '../Components/CommonComponents/Navbarp'
import SideNav from '../Components/CommonComponents/SideNav'
import { BASE_URL } from '../Config/Config';
import { logout } from '../features/Loginuser';
import {  useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import '../css/List.css'
import Loader from '../Components/CommonComponents/Loader';
import { Navigate } from 'react-router-dom';
import DialogModal from '../Components/CommonComponents/DialogModal';
import SmallLoader from '../Components/CommonComponents/SmallLoader';

function EditRateListPage() {
    const token=useSelector((state) => state.loginuser.token);
    const Login = useSelector((state) => state.loginuser.Login);
    let dispatch=useDispatch();
    let params=useParams();
    const ratelistid=params.rate_list_id;
    const [show,setShow]=useState(false);
    const [loading,setLoading]=useState(true)
    const [message,setMessage]=useState("");
    const [rateListDetail,setRateListDetail]=useState(null);
    const [actionLoader,setActionLoader]=useState(false);
    const [statusloader,setStatusLoader]=useState(false)

    const [type,setType]=useState("");
    
    useEffect(() => {
      
      getratelistdetail()
      
     },[setRateListDetail]) 
        function getratelistdetail(){
          var fdata =new FormData();
          fdata.append("rate_list_id",params.rate_list_id)
          fetch(BASE_URL+"getratelistdetail", {
            method: "POST",
            headers: {
              //'X-XSRF-TOKEN': 'XSRF-TOKEN)',
                  'Accept': 'application/json',
                "Authorization": "Bearer "+token,
            },
            body:fdata,
            
            })
            
            .then((response) => {
              if (response.status===401){
                dispatch(logout());
              }else{
                return response.json()
              }
            })
            //Then with the data from the response in JSON...
            .then((data) => {
                setLoading(false);
                setRateListDetail(data.rateListDetail[0]);
                
              })
        }
        function updateRateList(e){
          e.preventDefault();  
          setStatusLoader(true)
            var rateListdata =new FormData(e.target);
            rateListdata.append("rate_list_id",e.target.rate_list_id.value)
          fetch(BASE_URL+"updatepredefinedratelist", {
            method: "POST",
            headers: {
              //'X-XSRF-TOKEN': 'XSRF-TOKEN)',
                  'Accept': 'application/json',
                "Authorization": "Bearer "+token,
            },
            body:rateListdata,
            
            })
            
            .then((response) => {
              if (response.status===401){
                dispatch(logout());
              }else{
                return response.json()
              }
            })
            //Then with the data from the response in JSON...
            .then((data) => {
              setType("status");
              setShow(true);
              setMessage(data.message);
              setActionLoader(false);
              setStatusLoader(false);
              e.target.reset();
            })
            getratelistdetail()
        }
function modelAction(show){
  setShow(show);
}
  if(Login==null||Login==false){
    return  <Navigate to="/" replace={true} />
    }
  return (
     <>
     <DialogModal 
        show={show} 
        message={message} 
        action={modelAction} 
        id="" 
        type={type}
        actionLoader={actionLoader}
       />
      <div className="container-fluid ps-0 pe-0">
        <div className="row">
          <div>
          <Navbarp />
          </div>
          <div>
            <h3 className='ms-3'style={{'margin-top':'200px'}}>Update Ratelist</h3>
            {loading?
            <Loader/>:
                <form onSubmit={updateRateList}>   
                    <div className='mt-4'>
                        <div className="row align-items-start ">
                        <div className="ms-3 mb-3 col-12 col-lg-2 me-1">
                                <label  className="form-label">Rate List Id</label>
                                <input type="text" name="rate_list_id" className="form-control"defaultValue={rateListDetail.rate_list_id} disabled/>
                                <div id="emailHelp" className="form-text">Id of Ratelist</div>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2 me-1">
                                <label  className="form-label">Rate List Name</label>
                                <input type="text" name="name" className="form-control" defaultValue={rateListDetail.name} required/>
                                <div id="emailHelp" className="form-text">Name of Ratelist.</div>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-3 me-1">
                                <label  className="form-label">Description</label>
                                <textarea type="text"  name="description" className="form-control" defaultValue={rateListDetail.description}/>
                                <div className="form-text">Ratelist Description.</div>
                            </div>
                            
                            <div className="ms-3 mb-3 col-12 col-lg-2 me-1">
                                <label className="form-label">Rate Chart type</label>
                                <select className="form-select text-right" aria-label="Default select example" name="rate_chart_setup_type" defaultValue={rateListDetail.rate_chart_setup_type} required>
                                      <option  value="">Type</option>
                                      <option value="general">General</option>
                                      <option value="cattle">Cattle</option>
                                 </select>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2 me-1">
                                <label  className="form-label">Rate chart type Cattle</label>
                                <select className="form-select text-right" aria-label="Default select example" name="rate_chart_setup_type_cattle" defaultValue={rateListDetail.rate_chart_setup_type_cattle} >
                                      <option  value="">Cattle</option>
                                      <option value="cow">Cow</option>
                                      <option value="buffalo">Buffalo</option>
                                 </select>
                            </div>
                           
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className="row align-items-start ">
                            <div className="mb-3 ms-3 me-3 col me-3">
                                <label  className="form-label">Configuration</label>
                                <textarea type="text"  name="configuration" className="form-control p-3" style={{height:"250px"}} defaultValue={JSON.stringify(rateListDetail.configuration)} required/>
                            </div>
                            
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className="row align-items-start ">
                        <div className="ms-3 mb-3 col-12 col-lg-6 me-3">
                                <label  className="form-label">Active</label>
                                <select className="form-select text-right" aria-label="Default select example" name="is_active" defaultValue={rateListDetail.is_active} required>
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                  </select>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-5 me-3">
                                <label  className="form-label">Deleted</label>
                                <select className="form-select text-right" aria-label="Default select example" name="is_deleted" defaultValue={rateListDetail.is_deleted} required>
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                  </select>
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-warning mt-3 btn-lg ms-3">
                    {statusloader?<SmallLoader loadingtext={"Submitting..."}/>:"Submit"}
                    </button>
                </form>  }  
          </div> 
        </div>
      </div>
  </>
  )
}

export default EditRateListPage