import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { DateTime} from 'luxon';
import '../../css/List.css';
import TextField from '@mui/material/TextField';
import SortComponent from '../CommonComponents/SortComponent';


function GetCustomerDairyRateList(props) {
   const [value,setValue]=useState(null);
    return (
    <div className='mt-3 customer-list-table'>
        
     <Table striped bordered hover responsive>
         <thead>
            <tr className='tablehead'>
                <th>SNo</th>
                <th>Rate List Id<SortComponent sort={props.sort} sortby={"rate_list_id"} /></th>
                <th>Name(English)</th>
                <th>Name(Hindi)</th>
                <th>Type Id</th>
                <th><div style={{width:"125px"}}>Rate Chart Setup Type </div></th>
                <th><div style={{width:"125px"}}>Rate Chart Setup Type Cattle</div></th>
                <th>Configuration </th>
                <th>Active </th>
                <th>Deleted</th>
                <th>Date Added <SortComponent sort={props.sort} sortby={"created_at"} /></th>
                <th>Date Modified<SortComponent sort={props.sort} sortby={"modified_at"} /></th>
            </tr>
            <tr className='text-center'>
                <th> </th>
                <th><input onChange={e=>props.searchParam(e.target.value,"rate_list_id")}/></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th>
                    <select className="form-select text-right" aria-label="Default select example" name="is_active" onChange={e=>props.searchParam(e.target.value,"is_active")}>
                                      <option  value="">Active</option>
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                    </select>
                </th>
                <th>
                    <select className="form-select text-right" aria-label="Default select example" name="is_deleted" onChange={e=>props.searchParam(e.target.value,"is_deleted")}>
                                      <option  value="">Deleted</option>
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                  </select>
                </th>
                <th><TextField type="date" className='text-center' onChange={e=>props.searchParam(e.target.value,"created_at")}/></th>
                <th><TextField type="date" className='text-center' onChange={e=>props.searchParam(e.target.value,"modified_at")}/></th>
            </tr>
         </thead>
          <tbody>
            { 
                props.data.map((list,count) =>{
                    return( 
                    <tr className='text-center' key={list.rate_list_id}>
                    <td>{++count}</td>
                    <td>{list.rate_list_id}</td>
                    <td>{list.name?.en}</td>
                    <td>{list.name?.hi}</td>
                    <td>{list.rate_list_type_id}</td>
                    <td>{list.rate_chart_setup_type}</td>
                    <td>{list.rate_chart_setup_type_cattle}</td>
                    <td>
                        <button className='btn btn-outline-success d-block' onClick={() =>  navigator.clipboard.writeText(JSON.stringify(list.configuration))}>
                                    <i className="bi bi-clipboard"/>
                        </button>
                        {JSON.stringify(list.configuration)}
                    </td>
                    <td>{list.is_active}</td>
                    <td>{list.is_deleted}</td>        
                    <td>{list.created_at?DateTime.fromISO(list.created_at).toFormat("dd-LLL-yyyy"):"--"}</td>
                    <td>{list.modified_at?DateTime.fromSQL(list.modified_at).toFormat("dd-LLL-yyyy"):"--"}</td>
                    
                    </tr>)})
            }
        </tbody>
        </Table>
   
    
  </div>
  )
}

export default GetCustomerDairyRateList