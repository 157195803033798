import React from 'react'
import  Form  from 'react-bootstrap/Form';
import Button  from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { logout } from '../features/Loginuser';
import Navbarp from '../Components/CommonComponents/Navbarp'
import SideNav from '../Components/CommonComponents/SideNav'
import { useState,useEffect } from 'react';
import  {checkspecial,required,checkminlenght,checkuppercase,checknumber,checkemail,checkmaxlenght,checklowercase}  from '../Common/validation';
import { BASE_URL } from '../Config/Config';
import DialogModal from '../Components/CommonComponents/DialogModal';
import SmallLoader from '../Components/CommonComponents/SmallLoader';
import Otpmodal from '../Components/CommonComponents/Otpmodal';
function Password() {
      const Login = useSelector((state) => state.loginuser.Login)
      const [Password, setPassword] = useState("")
      const [Errpassword,setErrpassword]=useState("")
      const [errOldPassword,setErrOldPassword]=useState("")
      const [cnfpassworderr,setcnfpassworderr]=useState("")
      const [passwordtype,setpasswordtype]=useState("password")
      const [oldPasswordType,setOldPasswordType]=useState("password")
      const [cnfpasswordtype,setcnfpasswordtype]=useState("password")
      const [oldPassword,setOldPassword]=useState(null);
      const [error,setError]=useState(false)
      const [show,setShow]=useState(false);
      const [message,setMessage]=useState("");
      const [type,setType]=useState("")
      const [actionLoader,setActionLoader]=useState(false) 
      const [loading,setLoading]=useState(false)
      const [otpShow,setOtpShow]=useState(false)
      const token=useSelector((state) => state.loginuser.token);
      let dispatch=useDispatch();
      function handleSubmit(e){
        
          e.preventDefault();
         const pasval= validate(Password,"password")
         const cnfpasval= validate(Password,"confirmpassword")
         if (pasval&&cnfpasval)  { 
          changepassword(); 
                     
          }
       
      }
      function changepassword(){
        setLoading(true)
        setMessage("")
        var data =new FormData();
            data.append("oldPassword",oldPassword)
            data.append("Password",Password)
           
            fetch(BASE_URL+"changepassword", {
                method: "POST",
                headers: {
                  //'X-XSRF-TOKEN': 'XSRF-TOKEN)',
                      'Accept': 'application/json',
                    "Authorization": "Bearer "+token,
                },
                body:data,
                
                })
                
                .then((response) => {
                  if (response.status===401){
                    dispatch(logout());
                  }else{
                    return response.json()
                  }
                })
                //Then with the data from the response in JSON...
                .then((data) => {
                  if(data.status==true){
                    setOtpShow(true);
                    setLoading(false)
                  }else{
                    setType("status");
                    setShow(true);
                    setMessage(data.message);
                    setActionLoader(false)
                    setLoading(false);
                  }
                  

                })
        }
  function updatepassword(otp){
    setActionLoader(true)
    var data =new FormData();
    data.append("Password",Password)
    data.append("otp",otp)
    fetch(BASE_URL+"updatepassword", {
        method: "POST",
        headers: {
          //'X-XSRF-TOKEN': 'XSRF-TOKEN)',
              'Accept': 'application/json',
            "Authorization": "Bearer "+token,
        },
        body:data,
        
        })
        
        .then((response) => {
          if (response.status===401){
            dispatch(logout());
          }else{
            return response.json()
          }
        })
        //Then with the data from the response in JSON...
        .then((data) => {
          if (data.status==true){
            setOtpShow(false);
            setType("status");
            setShow(true);
            setMessage(data.message);
            setActionLoader(false)
            setLoading(false);
          }else{
            setMessage(data.message);
            setActionLoader(false)
          }
          
        })
  }      
  function modelAction(show){
    setShow(show);
    }    
  function otpModalAction(show){
    setOtpShow(show);
    }      
      
      function validate(data,type){
        var maxlength=25;
        var minLength=15
       switch(type) {
        case "oldpassword":
          if(required(data)){
            setErrOldPassword("Password cannot be left blank ")
            setError(true)
            return false;
          }else{
            setErrOldPassword(null)
            setError(false)
            setOldPassword(data);
          }
          break;

        case "password":
          if(required(data)){
            setErrpassword("Password cannot be left blank ")
            setError(true)
            return false;
          }
          else if(checkminlenght(data,minLength)) {
            setErrpassword("password must have atleast "+minLength+" character")
            setError(true)
            return false;
          }
          else if(checkmaxlenght(data,maxlength)) {
            setErrpassword("Password must be smaller than "+maxlength+" character")
            setError(true)
            return false;
          }
          else if(!checkspecial(data)){
            setErrpassword("Please include at least one Special character in it(~`!@#$%^&*()+=|{}:;\"',<>.?\[\/\\\])")
            setError(true)
            return false;
          }
          else if(!checkuppercase(data)){
            setErrpassword("Please include at least one Uppercase character in it")
            setError(true)
            return false;
          }
          else if(!checklowercase(data)){
            setErrpassword("Please include at least one Lowercase character in it")
            setError(true)
            return false;
          }
          else if(!checknumber(data)){
            setErrpassword("Please include at least one Numeric")
            setError(true)
            return false;
          }
          else{
            setErrpassword(null)
            setError(false)
            setPassword(data);
          }
          break;
          case "confirmpassword":
            if(required(data)){
              setcnfpassworderr("Password cannot be left blank ")
              setError(true)
              return false;
            }
          if(data!=Password)
            {
              setcnfpassworderr("Password and confirm Password do not match")
              setError(true);
              return false;
              
            } else {
              setcnfpassworderr(null)
              setError(false);
            }
          break;
          default:
           
      }
      return true
    }
    
    function setPasswordtype(){
     
      if(passwordtype=="password")
      { 
        setpasswordtype("text")
      }else {
      setpasswordtype("password")
      }  }
     function setcnfPasswordtype(){ 
      if(cnfpasswordtype=="password")
      { 
        setcnfpasswordtype("text")
      }else {
      setcnfpasswordtype("password")
      }   
    }
    function setoldpasswordType(){ 
      if(oldPasswordType=="password")
      { 
        setOldPasswordType("text")
      }else {
        setOldPasswordType("password")
      }   
    }
    if(Login==null||Login==false){
      return  <Navigate to="/" replace={true} />
      }
  return (
    <>
   <Otpmodal 
        show={otpShow} 
        action={updatepassword} 
        modalaction={otpModalAction} 
        actionLoader={actionLoader}
        message={message}
    />
   <DialogModal 
        show={show} 
        message={message} 
        action={modelAction} 
        id="" 
        type={type}
        actionLoader={actionLoader}
       />
      <div className="container-fluid p-0 pe-0">
        <div className="row">
          <div>
           <Navbarp />
          </div>
          <h3 className='ms-3' style={{"marginTop":"225px"}}>Change Password</h3>
          <div>
          <Form className="m-4" onSubmit={ handleSubmit} >
          <Form.Group className="mt-3" controlId="formBasicPassword">
            <Form.Label>Enter Old Password</Form.Label>
            <InputGroup className="mt-1">
            <Form.Control type={oldPasswordType} placeholder="Enter your old password" name="" onChange={e=>{validate(e.target.value,"oldpassword")}} onBlur={ e=>{validate(e.target.value,"oldpassword")}} />
            <Button variant="outline-primary" onClick={setoldpasswordType}>
              { oldPasswordType==="password"? <i className="bi bi-eye-slash"></i> :<i className="bi bi-eye"></i> }
            </Button>
            </InputGroup>
            <Form.Text className="text-muted">
            {errOldPassword ? <span className='text-danger'>{errOldPassword}</span>: null} .<br/>
            </Form.Text>
          </Form.Group>
          <Form.Group className="mt-3" controlId="formBasicPassword">
            <Form.Label>Enter New Password</Form.Label>
            <InputGroup className="mt-1">
            <Form.Control type={passwordtype} onChange={e=>{validate(e.target.value,"password")}} onBlur={ e=>{validate(e.target.value,"password")}} placeholder="Please enter new password" />
            <Button variant="outline-primary" onClick={setPasswordtype}>
            { passwordtype==="password"? <i className="bi bi-eye-slash"></i> :<i className="bi bi-eye"></i> }
            </Button>
            </InputGroup>
            <Form.Text className="text-muted">
            The Password should contain atleast one small and upper case character, a number and a special character <br/>
            {Errpassword ? <span className='text-danger'>{Errpassword}</span>: null}
            
            </Form.Text>
          </Form.Group>
          <Form.Group className="mt-3" controlId="formBasicPassword">
            <Form.Label>Confirm New Password</Form.Label>
            <InputGroup className="mt-1">
            <Form.Control type={cnfpasswordtype} onChange={e=>{validate(e.target.value,"confirmpassword")}} onBlur={ e=>{validate(e.target.value,"confirmpassword")}} placeholder="Please re enter the new password" />
            <Button variant="outline-primary" onClick={setcnfPasswordtype}>
            { cnfpasswordtype==="password"? <i className="bi bi-eye-slash"></i> :<i className="bi bi-eye"></i> }
            </Button>
            </InputGroup>
             {cnfpassworderr ? <span className='text-danger'>{cnfpassworderr}</span>: null} 
          </Form.Group>
          <Button className="mt-5" variant="warning" type="submit" size='lg' disabled={error} >
          {loading?<SmallLoader loadingtext={"Submitting..."}/>:"Submit"}
          </Button>
          
          </Form>
          </div>    
        </div>
      </div>
  </>
  )
}

export default Password