import {BrowserRouter,Link,Route,Routes} from 'react-router-dom'
import Login from './Mainpages/Login';
import Dashboard from './Mainpages/Dashboard';
import Customers from './Mainpages/Customers';
import Dairy from './Mainpages/Dairy';
import Orders from './Mainpages/Orders';
import Payments from './Mainpages/Payments';
import Subscriptions from './Mainpages/Subscriptions';
import ProfilePage from './Mainpages/ProfilePage';
import Password from './Mainpages/Password';
import Coupons from './Mainpages/Coupons';
import Plans from './Mainpages/Plans';
import GeneralRateList from './Mainpages/GeneralRateList';
import CustomerDetailpage from './Editpages/CustomerDetailpage';
import CouponDetailpage from './Editpages/CouponDetailpage';
import AddCouponPage from './Addpages/AddCouponPage';
import PlanDetailpage from './Editpages/PlanDetailpage';
import AddPlanPage from './Addpages/AddPlanPage';
import OtpVerification from './Mainpages/OtpVerification';
import CustomerDairyRateList from './Mainpages/CustomerDairyRateList';
import AddRateListPage from './Addpages/AddRateListPage';
import EditRateListPage from './Editpages/RateListPage';
import CustomerLogin from './Mainpages/CustomerLogin';
import Subscriptiondetail from './Editpages/Subscriptiondetail';
import Offers from './Mainpages/Offer';
import OfferDetailpage from './Editpages/OfferDetailpage';
import AddOfferPage from './Addpages/AddOfferPage';
import Forgotpassword from './Mainpages/Forgotpassword';
import PageNotFound from './Mainpages/PageNotFound';
import CustomerallDetailpage from './Editpages/CustomerallDetailpage';

function App() {
  
   return (
    <>
    
    <BrowserRouter>
    
      <Routes>
        <Route path="/" element={<Login />}/>        
        <Route path="dashboard" element={<Dashboard/>}/>
        <Route path="forgotpassword" element={<Forgotpassword/>}/>
        <Route path="customers" element={<Customers/>} />
        <Route path="customers/:customer_id" element={<CustomerDetailpage/>}/>
        <Route path="customers/details/:customer_id" element={<CustomerallDetailpage/>}/>
        <Route path="customerlogin/:customer_id" element={<CustomerLogin/>}/>
        <Route path="dairy" element={<Dairy/>} />
        <Route path="dairy/:id/:name" element={<CustomerDairyRateList/>}/>
        <Route path="orders" element={<Orders/>} />
        <Route path="payments" element={<Payments/>} />
        <Route path="subscriptions" element={<Subscriptions/>} />
        <Route path="subscriptions/:subscription_id" element={<Subscriptiondetail/>}/>
        <Route path="password" element={<Password/>} />
        <Route path="profilePage" element={<ProfilePage/>} />
        <Route path="otpverification" element={<OtpVerification/>}/>
        <Route path="coupons" element={<Coupons/>}/>
        <Route path="coupons/addcoupon" element={<AddCouponPage/>}/>
        <Route path="coupons/:coupon_id" element={<CouponDetailpage/>}/>
        <Route path="offers" element={<Offers/>}/>
        <Route path="offers/addoffer" element={<AddOfferPage/>}/>
        <Route path="offers/:offer_id" element={<OfferDetailpage/>}/>
        <Route path="plans" element={<Plans/>}/>
        <Route path="plans/:plan_id" element={<PlanDetailpage/>}/>
        <Route path="plans/addplan" element={<AddPlanPage/>}/>
        <Route path="ratelist" element={<GeneralRateList/>}/>
        <Route path="ratelist/:rate_list_id" element={<EditRateListPage/>}/>
        <Route path="ratelist/addratelist" element={<AddRateListPage/>}/>
        <Route path="*" element={<PageNotFound/>}/>
       
      </Routes>
   </BrowserRouter>
     
     </>
  );
}

export default App;
