import {configureStore} from '@reduxjs/toolkit'
import loginuser from './Loginuser';
import  coupon  from './Coupon';
import plan from './Plan';
export const store=configureStore({
 reducer:{
    coupon:coupon,
    loginuser:loginuser,
    plan:plan,
 }
});