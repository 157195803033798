import React from 'react';
import Table from 'react-bootstrap/Table';
import '../../css/List.css';
import { DateDifference } from '../../Common/DateDifference';
import PaymentStatus from '../CommonComponents/PaymentStatus';

function DashboardList(props) {
    
    return (
    <div className='mt-3 customer-list-table'>
     <Table striped  hover responsive>
         <thead>
            <tr className='tablehead'>
                <th>SNo</th>
                <th>Order id</th>
                <th>Amount </th>
                <th>Payment id </th>
                <th>Attempts</th>
                <th>Status </th>
                <th>Time</th>
                
            </tr>
         </thead>
          <tbody>
            { 
                props.data?props.data.map((list,count) =>{
                    return( 
                    <tr className='text-center' key={list.order_id}>
                        <td>{++count}</td>
                        <td>{list.order_id}</td>
                        <td>{parseFloat(list?.total).toFixed(2)}</td>
                        <td>{list?.integration_payment_id?list.integration_payment_id:"---"}</td>
                        <td>{list?.integration_order_response?.attempts}</td>
                        <td><PaymentStatus status={list.order_status}/></td>
                        <td>
                            {DateDifference(list.date_added)}
                        </td>
                    </tr>)}):null
            }
        </tbody>
        </Table>
   
    
  </div>
  )
}

export default DashboardList