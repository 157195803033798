import React from 'react'

function DurationDropDown(props) {
  return (
    <select className="form-select text-center"  aria-label="Default select example" style={{width: "100%"}} 
      onChange={e=>{props?.searchParam ? props.searchParam(e.target.value,"duration"):props.action(e.target.value)}}
      defaultValue={props.defaultValue?props.defaultValue:null}
    >
                        <option value="">Select Duration</option>
                        <option value="1">1 Month</option>
                        <option value="3">3 Month</option>
                        <option value="6">6 Month</option>
                        <option value="12">12 Month</option>
                     </select>
  )
}

export default DurationDropDown