import React, { useEffect,useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loader from '../Components/CommonComponents/Loader';
import Navbarp from '../Components/CommonComponents/Navbarp'
import SideNav from '../Components/CommonComponents/SideNav'
import { BASE_URL } from '../Config/Config';
import { logout } from '../features/Loginuser';
import { DateTime } from 'luxon';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Navigate } from 'react-router-dom';
import DialogModal from '../Components/CommonComponents/DialogModal';
import SmallLoader from '../Components/CommonComponents/SmallLoader';

function PlanDetailpage() {
    const token=useSelector((state) => state.loginuser.token);
    const Login = useSelector((state) => state.loginuser.Login);
    let params = useParams();
    let dispatch=useDispatch();
    const [loading,setLoading]=useState(true);
    const [plandetail,setPlanDetail]=useState(true);
    const [show,setShow]=useState(false);
    const [message,setMessage]=useState("")
    const [actionLoader,setActionLoader]=useState(false);
    const [type,setType]=useState("");
        const [statusloader,setStatusLoader]=useState(false)

     
    useEffect(() => {
      var fdata =new FormData();
      fdata.append("plan_id",params.plan_id)
          fetch(BASE_URL+"getplandetail", {
              method: "POST",
              headers: {
                //'X-XSRF-TOKEN': 'XSRF-TOKEN)',
                    'Accept': 'application/json',
                  "Authorization": "Bearer "+token,
              },
              body:fdata,
              
              })
              
              .then((response) => {
                if (response.status===401){
                  dispatch(logout());
                }else{
                  return response.json()
                }
              })
              //Then with the data from the response in JSON...
              .then((data) => {
                  setLoading(false);
                  setPlanDetail(data.plandetail[0]);
                  
              })
            },[setPlanDetail])
            
        function updatedata(e){
          const name_local={};
          name_local.en=e.target.name_local_en.value;
          name_local.hi=e.target.name_local_hi.value;
            e.preventDefault();
            setStatusLoader(true)
            var updatedata =new FormData(e.target);
            updatedata.append("plan_id",e.target.plan_id.value)
            updatedata.append("name_local",JSON.stringify(name_local))
           
            fetch(BASE_URL+"updateplandetail", {
                method: "POST",
                headers: {
                  //'X-XSRF-TOKEN': 'XSRF-TOKEN)',
                      'Accept': 'application/json',
                    "Authorization": "Bearer "+token,
                },
                body:updatedata,
                
                })
                
                .then((response) => {
                  if (response.status===401){
                    dispatch(logout());
                  }else{
                    return response.json()
                  }
                })
                //Then with the data from the response in JSON...
                .then((data) => {
                  setType("status");
                  setShow(true);
                  setMessage(data.message);
                  setActionLoader(false)
                  setStatusLoader(false)
                   
                })
        }
  function modelAction(show){
    setShow(show);
  }
  if(Login==null||Login==false){
    return  <Navigate to="/" replace={true} />
    }    
  return (
    <>
    <DialogModal 
        show={show} 
        message={message} 
        action={modelAction} 
        id="" 
        type={type}
        actionLoader={actionLoader}
       />
      <div className="container-fluid ps-0 pe-0">
        <div className="row">
          <div>
          <Navbarp />
          </div>
          <div>
            <h3 className='mt-2' style={{'margin-top':'200px'}}>Plan Detail</h3>
            {loading?
            <Loader/>:
                <form onSubmit={updatedata}>   
                    <div className='mt-4'>
                        <div className="row align-items-start ">
                            <div className="ms-3 mb-3 col-12 col-lg-2 me-1">
                                <label className="form-label">Plan Id</label>
                                <input type="number" name="plan_id" className="form-control" defaultValue={plandetail.plan_id} disabled />
                                <div id="emailHelp" className="form-text">Id of Plan.</div>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2 me-1">
                                <label  className="form-label">Plan Name</label>
                                <input type="text" name="name" className="form-control" defaultValue={plandetail.name} />
                                <div id="emailHelp" className="form-text">Name of Plan.</div>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2 me-1">
                                <label  className="form-label">Description</label>
                                <input type="text"  name="description" className="form-control" defaultValue={plandetail.description} />
                                <div className="form-text">Plan Description.</div>
                            </div>
                            
                            <div className="ms-3 mb-3 col-12 col-lg-2 me-1">
                                <label className="form-label">Priority</label>
                                <input type="text" name="priority" className="form-control" defaultValue={plandetail.priority}  />
                                
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-3 me-1">
                                <label  className="form-label">Is last</label>
                                <select className="form-select text-right" aria-label="Default select example" name="is_last" defaultValue={plandetail.is_last}>
                                      <option  value="">Select is Last</option>
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className="row align-items-start ">
                        <div className="ms-3 mb-3 col-12 col-lg-2 me-1">
                                <label className="form-label">Name English</label>
                                <input type="text" name="name_local_en" className="form-control" defaultValue={plandetail.name_local.en}  />
                                
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2 me-1">
                                <label className="form-label">Name Hindi</label>
                                <input type="text" name="name_local_hi" className="form-control" defaultValue={plandetail.name_local.hi}  />
                                
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2 me-1">
                                <label className="form-label">Is Trial</label>
                                <select className="form-select text-right" aria-label="Default select example" name="is_trial"  defaultValue={plandetail.is_trial}>
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                </select>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2 me-1">
                                <label  className="form-label">Show on UI</label>
                                <select className="form-select text-right" aria-label="Default select example" name="show_on_ui"  defaultValue={plandetail.show_on_ui} >
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                </select>
                            </div>
                           
                            <div className="ms-3 mb-3 col-12 col-lg-2 me-1">
                                <label  className="form-label">Show on Feature List</label>
                                <select className="form-select text-right" aria-label="Default select example" name="show_on_feature_list" defaultValue={plandetail.show_on_feature_list} >
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                  </select>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-1 me-1">
                                <label  className="form-label">Is Active</label>
                                <select className="form-select text-right" aria-label="Default select example" name="is_active"  defaultValue={plandetail.is_active}>
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className="row align-items-start ">
                        <div className="ms-3 mb-3 col-12 col-lg-2 me-1">
                                <label  className="form-label">Daily Price</label>
                                <input type="text"  name="daily_price" className="form-control" defaultValue={plandetail.daily_price} />
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2 me-1">
                                <label  className="form-label">Monthly Price</label>
                                <input type="number"  name="monthly_price" className="form-control" defaultValue={plandetail.monthly_price} />
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2 me-1">
                                <label  className="form-label">Quarterly Price</label>
                                <input type="number"  name="quarterly_price" className="form-control" defaultValue={plandetail.quarterly_price}/>
                                <div className="form-text"></div>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2 me-1">
                                <label  className="form-label">Half Yearly Price</label>
                                <input type="text"  name="half_yearly_price" className="form-control" defaultValue={plandetail.half_yearly_price} />
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-3 me-1">
                                <label  className="form-label">Annually Price</label>
                                <input type="text"  name="annually_price" className="form-control" defaultValue={plandetail.annually_price}  />
                            </div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className="row align-items-start ">
                        <div className="ms-3 mb-3 col-12 col-lg-6 me-1">
                                <label  className="form-label">Date Added</label>
                                <input type="text"  name="date_added" className="form-control" defaultValue={DateTime.fromISO(plandetail.date_added).toFormat("yyyy-LL-dd hh:mm a")} disabled />
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-5 me-1">
                                <label  className="form-label">Date Modified</label>
                                <input type="text"  name="date_modified" className="form-control" defaultValue={DateTime.fromISO(plandetail?.date_modified).toFormat("yyyy-LL-dd hh:mm a")} disabled  />
                            </div>
                        </div>
                    </div>
                    <button type="submit" className=" ms-3 btn btn-warning mt-3 btn-lg">
                    {statusloader?<SmallLoader loadingtext={"Submitting..."}/>:"Submit"}
                    </button>
              </form> }   
          </div> 
        </div>
      </div>
  </>
  )
}

export default PlanDetailpage