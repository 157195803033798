import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { DateTime} from 'luxon';
import '../../css/List.css';
import TextField from '@mui/material/TextField';
import SortComponent from '../CommonComponents/SortComponent';
import { Link } from 'react-router-dom';

function CouponList(props) {
   const [value,setValue]=useState(null);
    return (
    <div className='mt-3 customer-list-table'>
        
     <Table striped bordered hover responsive>
         <thead>
            <tr className='tablehead'>
                <th>SNo</th>
                <th><SortComponent sort={props.sort} sortby={"coupon_id"} />Coupon Id</th>
                <th><SortComponent sort={props.sort} sortby={"name"} />Name </th>
                <th><SortComponent sort={props.sort} sortby={"code"} />Code </th>
                <th><SortComponent sort={props.sort} sortby={"type"} />Type </th>
                <th><SortComponent sort={props.sort} sortby={"discount"} />Discount </th>
                <th><SortComponent sort={props.sort} sortby={"count"} />Coupon Used </th>
                <th><SortComponent sort={props.sort} sortby={"start_date"} />Start Date</th>
                <th><SortComponent sort={props.sort} sortby={"end_date"} /> End Date</th>
                <th><SortComponent sort={props.sort} sortby={"uses_total"} />Total Use</th>
                <th><SortComponent sort={props.sort} sortby={"uses_customer"} /> Per Customer Use</th>
                <th><SortComponent sort={props.sort} sortby={"is_active"} /> Active</th>
                <th><SortComponent sort={props.sort} sortby={"date_added"} />Created On</th>
                <th>Action</th>
            </tr>
            <tr className='text-center'>
                <th> </th>
                <th><input onChange={e=>props.searchParam(e.target.value,"coupon_id")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"name")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"code")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"type")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"discount")}/></th>
                <th><div></div></th>
                <th><TextField type="date" className='text-center' onChange={e=>props.searchParam(e.target.value,"start_date")}/></th>
                <th><TextField type="date" className='text-center' onChange={e=>props.searchParam(e.target.value,"end_date")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"uses_total")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"uses_customer")}/></th>
                <th>
                    <select className="form-select text-right" aria-label="Default select example" name="is_active" onChange={e=>props.searchParam(e.target.value,"is_active")}>
                                        <option  value="">Select Status</option>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                    </select>
                </th>

                <th><TextField type="date" className='text-center' onChange={e=>props.searchParam(e.target.value,"date_added")}/></th>
            </tr>
         </thead>
          <tbody>
            { 
                props.data.map((list,count) =>{
                    return( 
                    <tr className='text-center' key={list.coupon_id}>
                        <td>{++count}</td>
                        <td>{list.coupon_id}</td>
                        <td>{list.name}</td>
                        <td>{list.code}</td>
                        <td>{list.type=='P'?"Percentage":"Flat"}</td>
                        <td>{list.type=='P'?list.discount+" %":list.discount+" Rs"}</td>
                        <td>{list.coupon_history_count}</td>
                        <td>{list.start_date?DateTime.fromSQL(list.start_date).toFormat("dd-LLL-yyyy"):"--"}</td>
                        <td>{list.end_date?DateTime.fromSQL(list.end_date).toFormat("dd-LLL-yyyy"):"--"}</td>
                        <td>{list.uses_total}</td>
                        <td>{list.uses_customer}</td>
                        <td>{list.is_active}</td>
                        <td>{list.date_added?DateTime.fromISO(list.date_added).toFormat("dd-LLL-yyyy"):"--"}</td>
                        <td><div style={{width: "150px"}}>
                            <Link to={`/coupons/${list.coupon_id}`}>
                                <button className='btn btn-outline-success' style={{width: "50px"}} >
                                        <i className="bi bi-pen me-1"/>
                                    </button>
                            </Link>
                            <button className='btn btn-outline-danger ms-2' style={{width: "50px"}} onClick={e=>props.returncoupon_id(list.coupon_id)}  >
                                <i className="bi bi-trash3"></i>
                            </button>
                            </div>
                        </td>
                    </tr>)})
            }
        </tbody>
        </Table>
   
    
  </div>
  )
}

export default CouponList