import React from 'react'
import Navbarp from '../Components/CommonComponents/Navbarp'

function PageNotFound() {
  return (
    <div>
      
      <Navbarp/>
      <div className='d-flex w-100 justify-content-center '>
      <h2 className='text-center fs-1 m-5 p-3  fw-bold'>Sorry This Page does not Exists</h2>
    </div>
    </div>
  )
}

export default PageNotFound