import React, { useState } from 'react'
import {useSelector} from 'react-redux'
import Select from 'react-select'
import { coupon } from '../../features/Coupon';
function CouponDropDown(props) {
    const coupondata = useSelector((state) => state.coupon.coupon);
    const selectdata=coupondata.map((data)=>{
      return {
        value:data.coupon_id,
        label:data.coupon_id+"-"+data[props.column]
      }       
    })
    const index=selectdata.findIndex(checkCoupon)
    function checkCoupon(data) {
      return data.value== props.defaultValue;
    }
    return (
    <>
      <div style={{width: "100%"}}>
        <Select 
          options={selectdata}  
          onChange={(value)=>{props?.searchParam ? props.searchParam(value.value,"coupon_id"):props.coupon_id(value.value)}}
          defaultValue={selectdata[index]}
        />
      </div>
      {/* my custome code i created for select  */}
    {/* <select className="form-select text-center"  aria-label="Default select example" style={{width: "200px"}} onChange={e=>{props.searchParam(e.target.value,"coupon_id")}}>
                        <option value="">Select Coupon {props.column}</option>
                      {coupondata?.coupon?.map((data) => {
                        console.log(data)
                        return(
                          
                            <option key={data.coupon_id} value={data.coupon_id}>{data[props.column]}</option>
                        )

                      })}
                     </select> */}
                     </>
    
  )
}

export default CouponDropDown