import React, { useEffect, useState } from 'react'
import OtpInput from 'react-otp-input'
import Navbarp from '../Components/CommonComponents/Navbarp'
import '.././css/Otp.css' ;
import { BASE_URL } from '../Config/Config';
import { useDispatch, useSelector } from 'react-redux';
import { setCoupanData } from '../features/Coupon';
import { setplans } from '../features/Plan';
import { logout, setTempToken } from '../features/Loginuser';
import { setlogin } from '../features/Loginuser';
import {Navigate} from 'react-router-dom';
import SmallLoader from '../Components/CommonComponents/SmallLoader';

function OtpVerification() {
    const token=useSelector((state) => state.loginuser.token);
    const [otp,setOtp]=useState('******');
    const [error,setError]=useState("");
    const [errorotp,setErrorOtp]=useState(false);
    const Login = useSelector((state) => state.loginuser.Login);
    const tempToken = useSelector((state) => state.loginuser.tempToken);
    const [loading,setLoading]=useState(false);
    const [resending,setResending]=useState(false);
    const [redirect,setRedirect]=useState(false);
    const dispatch= useDispatch();
    function getotp(otp){
        setOtp(otp);
       
    }
    useEffect(() => {
      const getData = setTimeout(() => {
      }, 1000)
      return () => clearTimeout(getData)
    }, [redirect])
    
   function verifyOtp(e){
         e.preventDefault();
         setLoading(true);
        fetch(BASE_URL+"verifyotp", {
          method: "POST",
          headers: {
                'Accept': 'application/json',
              'Content-Type': 'application/json',
          },
          body:JSON.stringify({
                    "otp":otp,
                    "tempToken":tempToken
                }),

          })
          
          .then((response) => response.json())
        //Then with the data from the response in JSON...
        .then((data) => {
            if(data.status==true){
              setLoading(false);
              dispatch(setlogin(data));
              dispatch(setTempToken(""));
            }else{
              setLoading(false);
              setError(data.message);
              setErrorOtp(!data.status);
              setRedirect(data.redirect);
            }
          })
  }
   
   function resendOtp(e,otpBy){
        e.preventDefault();
        setResending(true)
        fetch(BASE_URL+"resendotp", {
            method: "POST",
            headers: {
                  'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify({
                      "tempToken":tempToken,
                      "otpby":otpBy
                  }),
  
            })
            .then((response) => response.json())
          //Then with the data from the response in JSON...
          .then((data) => {
              if(data.status==true){
                setError(data.message);
                setResending(false)
                dispatch(setTempToken(data.tempToken));
              }else{
                setRedirect(data.redirect);
                setError(data.message);
                setResending(false)

              }
            })
        
   }
   function getplan(){

    fetch(BASE_URL+"plans?page=", {
        method: "GET",
        headers: {
          //'X-XSRF-TOKEN': 'XSRF-TOKEN)',
              'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer "+token,
        }
  
        })
        
        .then((response) => {
          if (response.status===401){
            dispatch(logout());
          }else{
            return response.json()
          }
        })
        //Then with the data from the response in JSON...
        .then((data) => { 
            dispatch(setplans(data.result.data))
        })
        
   }
   function getcoupon(){
    fetch(BASE_URL+"coupon?page=", {
        method: "GET",
        headers: {
          //'X-XSRF-TOKEN': 'XSRF-TOKEN)',
              'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer "+token,
        }

        })
        
        .then((response) => {
          if (response.status===401){
            dispatch(logout());
          }else{
            return response.json()
          }
        })
        //Then with the data from the response in JSON...
        .then((data) => {
            dispatch(setCoupanData(data.result.data))
        })
   }
   
   if(Login==true){
        getplan();
        getcoupon();
        return  <Navigate to="/dashboard" replace={true} />
   }
   if(redirect==true){
    return  <Navigate to="/" replace={true} />

   }
  return (
    <div>

        <Navbarp/>
        <div className='container-fluid mt-5'>
          <div class="d-flex w-100 flex-row justify-content-center">
            <div class="d-flex w-100 flex-column justify-content-center" style={{"max-width":"500px","marginTop":"150px"}}>
              <p className='fs-3 fw-bold mb-5 text-center text-danger'>2 Factor Authentication </p>
              <p className='fs-5 fw-bold mb-5 text-center'>We have sent a 6 digit verification code to your mobile number</p>
              <div className='otpbox '> 
                <div className='card'>
                <form>
                <p className='fs-5 fw-bold text-center'>Please enter the verification code here </p>
                <OtpInput
                value={otp}
                onChange={getotp}
                containerStyle='margin-top--small'
                inputStyle='inputstyle'
                numInputs={6}
                isInputNum={true}
                separator={<span>-</span>}
                errorStyle='error'
                hasErrored={errorotp}
                />
                {error?<p className='fs-5 fw-bold mt-5 text-danger'>{error}</p>:null}
                <div class="d-flex flex-row mb-3 justify-content-center">
                  <button className='btn btn-outline-success me-2 mt-5 btn-lg' onClick={verifyOtp} disabled={resending}>
                    {loading?<SmallLoader loadingtext={"Confirming..."}/>:"Confirm"}
                  </button>
                  <button className='btn btn-outline-danger btn-lg ms-2 mt-5' onClick={(e)=>resendOtp(e,'text')}  disabled={loading}>
                  {resending?<SmallLoader loadingtext={"Messaging..."}/>:"Message OTP"}
                  </button>
                 
                </div>
                </form>
                </div>
            </div>
        
        </div>
        </div>

            
        </div>
    </div>
  )
}

export default OtpVerification