import React, { useEffect,useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loader from '../Components/CommonComponents/Loader';
import Navbarp from '../Components/CommonComponents/Navbarp'
import SideNav from '../Components/CommonComponents/SideNav'
import { BASE_URL } from '../Config/Config';
import { logout } from '../features/Loginuser';
import { DateTime } from 'luxon';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Navigate } from 'react-router-dom';
import DialogModal from '../Components/CommonComponents/DialogModal';
import SmallLoader from '../Components/CommonComponents/SmallLoader';

function CustomerDetailpage() {
    const token=useSelector((state) => state.loginuser.token);
    const Login = useSelector((state) => state.loginuser.Login);
    let params = useParams();
    const customer_id=params.customer_id;
    let dispatch=useDispatch();
    const [loading,setLoading]=useState(true);
    const [custdetail,setCustDetail]=useState(true);
    const [show,setShow]=useState(false);
    const [type,setType]=useState("");
    const [message,setMessage]=useState("");
    const [actionLoader,setActionLoader]=useState(false);
    const [statusloader,setStatusLoader]=useState(false)
 
    var fdata =new FormData();
      fdata.append("customer_id",params.customer_id)
    useEffect(() => {
          fetch(BASE_URL+"getcustomerdetail", {
              method: "POST",
              headers: {
                //'X-XSRF-TOKEN': 'XSRF-TOKEN)',
                    'Accept': 'application/json',
                  "Authorization": "Bearer "+token,
              },
              body:fdata,
              
              })
              
              .then((response) => {
                if (response.status===401){
                  dispatch(logout());
                }else{
                  return response.json()
                }
              })
              //Then with the data from the response in JSON...
              .then((data) => {
                  setLoading(false);
                  setCustDetail(data.customerdetail[0]);
                  console.log("custdetail",custdetail);
              })
            },[setCustDetail])
            
        function updatedata(e){
            e.preventDefault();
            setStatusLoader(true)
            var updatedata =new FormData(e.target);
            updatedata.append("customer_id",e.target.customer_id.value)
            fetch(BASE_URL+"updatecustomerdetail", {
                method: "POST",
                headers: {
                  //'X-XSRF-TOKEN': 'XSRF-TOKEN)',
                      'Accept': 'application/json',
                    "Authorization": "Bearer "+token,
                },
                body:updatedata,
                
                })
                
                .then((response) => {
                  if (response.status===401){
                    dispatch(logout());
                  }else{
                    return response.json()
                  }
                })
                //Then with the data from the response in JSON...
                .then((data) => {
                  setType("status");
                  setShow(true);
                  setMessage(data.message);
                  setActionLoader(false)
                  setStatusLoader(false)
                })
        }
  function modelAction(show){
    setShow(show);
  }
  if(Login==null||Login==false){
    return  <Navigate to="/" replace={true} />
    }    
  return (
    <div className="container-fluid ps-0 pe-0">
    <DialogModal 
        show={show} 
        message={message} 
        action={modelAction} 
        id="" 
        type={type}
        actionLoader={actionLoader}
       />
      <div className="container-fluid ps-0 pe-0">
        <div className="row">
          <div>
           <Navbarp />
          </div>
          <div>
            <h3 className='ms-3'style={{'margin-top':'200px'}}>Customer Detail</h3>
            {loading?
            <Loader/>:
                <form onSubmit={updatedata}>   
                    <div className='mt-4'>
                        <div className="row align-items-start ">
                            <div className="ms-3 mb-3 col-12  col-lg-1">
                                <label className="form-label">Customer Id</label>
                                <input type="number" name="customer_id" className="form-control" defaultValue={custdetail.customer_id} disabled />
                                <div id="emailHelp" className="form-text">Id of the Customer.</div>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2">
                                <label  className="form-label">First Name</label>
                                <input type="text" name="firstname" className="form-control" defaultValue={custdetail.firstname} disabled/>
                                <div id="emailHelp" className="form-text">First Name of Customer.</div>
                            </div>
                            <div className="ms-3 mb-3 col-12  col-lg-3">
                                <label  className="form-label">Last Name</label>
                                <input type="text"  name="lastname" className="form-control" defaultValue={custdetail.lastname} disabled/>
                                <div className="form-text">Last Name of Customer.</div>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2">
                                <label className="form-label">Mobile</label>
                                <input type="number" name="telephone" className="form-control" defaultValue={custdetail.telephone} disabled />
                                <div id="emailHelp" className="form-text">Mobile No of the Customer.</div>
                            </div>
                            <div className="ms-3 mb-3 col-12  col-lg-3 me-1">
                                <label  className="form-label">Email</label>
                                <input type="email"  name="email" className="form-control" defaultValue={custdetail.email} disabled/>
                                <div id="emailHelp" className="form-text">Email of Customer.</div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className="row align-items-start ">
                        <div className="ms-3 mb-3 col-12  col-lg-2 ">
                                <label className="form-label">Gender</label>
                                <input type="text"  name="gender" className="form-control" defaultValue={custdetail.gender} disabled />
                                <div id="emailHelp" className="form-text">Gender of the Customer.</div>
                            </div>
                            <div className="ms-3 mb-3 col-12  col-lg-3 me-3">
                                <label  className="form-label">IP</label>
                                <input type="text"  name="ip" className="form-control" defaultValue={custdetail.ip} disabled />
                                <div id="emailHelp" className="form-text">IP Address of  Customer.</div>
                            </div>
                           
                            <div className="ms-3 mb-3 col-12  col-lg-3 me-3">
                                <label  className="form-label">Date of Birth</label>
                                <input type="date"  name="dob" className="form-control" defaultValue={custdetail.dob} disabled/>
                                <div className="form-text">Date of Birth of Customer.</div>
                            </div>
                            <div className="ms-3 mb-3 col-12  col-lg-3 me-3">
                                <label  className="form-label">Account Created</label>
                                <input type="text"  name="date_added" className="form-control" defaultValue={DateTime.fromSQL(custdetail.date_added).toFormat("yyyy-LL-dd hh:mm a")}  disabled/>
                            </div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className="row align-items-start ">
                        <div className="ms-3 mb-3 col-12  col-lg-2 me-3">
                                <label  className="form-label">Customer Group</label>
                                <select className="form-select text-right" aria-label="Default select example" name="customer_group_id" defaultValue={custdetail.customer_group_id}>
                                      <option  value="">Select Type</option>
                                      <option value="1">Kisaan</option>
                                      <option value="2">Dairy Owner</option>
                                  </select>
                                <div className="form-text">Type of Customer(Farmer-1,Dairy Owner-2 ).</div>
                            </div>
                            <div className="ms-3 mb-3 col-12  col-lg-2 me-3">
                                <label  className="form-label">Status</label>
                                <input type="number"  name="status" className="form-control" defaultValue={custdetail.status} disabled/>
                                <div id="emailHelp" className="form-text">Statues of  Customer.</div>
                            </div>
                            <div className="ms-3 mb-3 col-12  col-lg-2 me-3">
                                <label  className="form-label">Login Attempts</label>
                                <input type="number"  name="login_attempts" className="form-control" defaultValue={custdetail.login_attempts}/>
                                <div className="form-text"></div>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2 me-3">
                                <label  className="form-label">Last Login</label>
                                <input type="text"  name="last_login" className="form-control" defaultValue={DateTime.fromSQL(custdetail.last_login).toFormat("yyyy-LL-dd hh:mm a")} disabled/>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-3 me-3">
                                <label  className="form-label">Blocked Upto</label>
                                <input type="date"  name="blocked_up_to" className="form-control" defaultValue={DateTime.fromSQL(custdetail.blocked_up_to).toFormat("yyyy-LL-dd")}  />
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-warning mt-3 ms-3 btn-lg" >
                    {statusloader?<SmallLoader loadingtext={"Submitting..."}/>:"Submit"}
                    </button>
                </form>   
} 
          </div> 
        </div>
      </div>
  </div>
  )
}

export default CustomerDetailpage