import React from 'react';
import Table from 'react-bootstrap/Table';
import { DateTime} from 'luxon';
import '../../css/List.css';
import TextField from '@mui/material/TextField';
import SortComponent from '../CommonComponents/SortComponent';
import DurationDropDown from '../Dropdown/DurationDropDown';
import PlanDropDown from '../Dropdown/PlanDropDown';
import { Link } from 'react-router-dom';

function SubscriptionList(props) {
    return ( 
    <div className='mt-3 customer-list-table'>
        
     <Table striped bordered hover responsive>
         <thead>
            <tr className='tablehead' >
                <th>SNo</th>
                <th><SortComponent sort={props.sort} sortby={"subscription_id"} />Subscription_Id</th>
                <th><SortComponent sort={props.sort} sortby={"customer_id"} />Customer Id</th>
                <th><SortComponent sort={props.sort} sortby={"firstname"} />Customer Name </th>
                <th><SortComponent sort={props.sort} sortby={"telephone"} />Mobile </th>
                <th>Plan Name</th>
                <th>Status</th>
                <th><SortComponent sort={props.sort} sortby={"order_id"}/>Order Id</th>
                <th>Duration</th>
                <th><SortComponent sort={props.sort} sortby={"payment_status"}/>Payment Status</th>
                <th><SortComponent sort={props.sort} sortby={"is_trial"}/>Is Trial</th>
                <th><SortComponent sort={props.sort} sortby={"trial_start_date"}/>Trial Start Date</th>
                <th><SortComponent sort={props.sort} sortby={"trial_end_date"}/>Trial End Date</th>
                <th><SortComponent sort={props.sort} sortby={"start_date"}/>Start Date</th>
                <th><SortComponent sort={props.sort} sortby={"end_date"}/>End Date</th>
                <th><SortComponent sort={props.sort} sortby={"date_added"}/>Date Added</th>
                <th><SortComponent sort={props.sort} sortby={"date_modified"}/>Date Modified</th>
                <th>Actions</th>
            </tr>
            <tr className='text-center'>
                <th> </th>
                <th><input onChange={e=>props.searchParam(e.target.value,"subscription_id")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"customer_id")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"firstname")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"telephone")}/></th>
                <th><PlanDropDown searchParam={props.searchParam}/></th>
                <th>
                    <select className="form-select text-center" aria-label="Default select example" style={{width: "170px"}} onChange={e=>props.searchParam(e.target.value,"status")}>
                            <option value="">Select Status</option>
                            <option value="active">Active</option>
                            <option value="expired">Expired</option>
                        </select>
                </th>
                <th><input onChange={e=>props.searchParam(e.target.value,"order_id")}/></th>
                
                <th>
                   <DurationDropDown searchParam={props.searchParam}/>
                </th>
                <th><input onChange={e=>props.searchParam(e.target.value,"payment_status")}/></th>
                <th>                   
                    <select className="form-select text-center" aria-label="Default select example" style={{width: "170px"}} onChange={e=>props.searchParam(e.target.value,"is_trial")}>
                            <option value="">Select Trial</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                    </select>
                </th>
                <th><TextField type="date" className='text-center' style={{width: "170px"}} onChange={e=>props.searchParam(e.target.value,"trial_start_date")}/></th>
                <th><TextField type="date" className='text-center' onChange={e=>props.searchParam(e.target.value,"trial_end_date")}/></th>
                <th><TextField type="date" className='text-center' style={{width: "170px"}} onChange={e=>props.searchParam(e.target.value,"start_date")}/></th>
                <th><TextField type="date" className='text-center' onChange={e=>props.searchParam(e.target.value,"end_date")}/></th>
                <th><TextField type="date" className='text-center' onChange={e=>props.searchParam(e.target.value,"date_added")}/></th>
                <th><TextField type="date" className='text-center' onChange={e=>props.searchParam(e.target.value,"date_modified")}/></th>
            </tr>
         </thead>
          <tbody>
            {  
                props.data.map((list,count) =>{
                    return( 
                    <tr className='text-center' key={list.subscription_id}>
                    <td>{++count}</td>
                    <td>{list.subscription_id}</td>
                    <td>{list.customer_id}</td>
                    <td>{(list.customer?.firstname||list.customer?.lastname)? list.customer.firstname+" "+list.customer.lastname:"---"}</td>
                    <td>{(list.customer?.telephone)? list.customer.telephone:"---"}</td>
                    <td>{list.plan?.name}</td>
                    <td>{list.status}</td>
                    <td>{list.order_id}</td>
                    <td>{list.order?.duration}</td>
                    <td>{list.order?.payment_status}</td>
                    <td>{list.is_trial}</td>
                    <td>{list?.trial_start_date?DateTime.fromSQL(list?.trial_start_date).toFormat("dd-LLL-yyyy"):"---"}</td>
                    <td>{list?.trial_end_date?DateTime.fromSQL(list?.trial_end_date).toFormat("dd-LLL-yyyy"):"---"}</td>
                    <td>{list?.start_date?DateTime.fromSQL(list?.start_date).toFormat("dd-LLL-yyyy"):"---"}</td>
                    <td>{list?.end_date?DateTime.fromSQL(list?.end_date).toFormat("dd-LLL-yyyy"):"---"}</td>
                    <td>{DateTime.fromISO(list.date_added).toFormat("dd-LLL-yyyy")}</td>
                    <td>{list?.date_modified?DateTime.fromISO(list.date_modified).toFormat("dd-LLL-yyyy"):"---"}</td>
                    <td><div style={{width: "150px"}}>
                            <Link to={`/subscriptions/${list.subscription_id}`} >
                                <button className='btn btn-outline-success' style={{width: "50px"}} >
                                    <i className="bi bi-pen me-1"/>
                                </button>
                                <button className='btn btn-outline-success ms-1' style={{width: "50px"}} >
                                    <i class="bi bi-bell"></i>
                                </button>
                            </Link>
                        </div>
                        </td>   
                    </tr>)})
            }
        </tbody>
        </Table>
   
    
  </div>
  )
}

export default SubscriptionList