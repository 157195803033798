import React from 'react'

function PaymentStatus(props) {
    let  background,textcol,content;
    if(props.status=="created")
    {
      background="bg-warning";
      textcol="text-black"
      content="Created"
    }
    if(props.status=="paid")
    {
      background="bg-success";
      textcol="text-white"
        content="Paid"
    }
    if(props.status=="attempted")
    {
      background="bg-danger";
      textcol="text-white"
      content="Failed"
    }
  return (
    <>
    <div className={background+" "+textcol+" border rounded-pill p-1"}>
    <span>
        {content}
    </span>
    </div>
    </>
  )
}

export default PaymentStatus