import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/esm/Button'
import {Link,Navigate,NavLink} from 'react-router-dom'
import {useSelector,useDispatch} from 'react-redux'
import { logout } from '../../features/Loginuser';
import { BASE_URL } from '../../Config/Config';
import '../../css/common.css'
import '../../css/LoginComponent.css'
import Dropdown from 'react-bootstrap/Dropdown';
import SmallLoader from './SmallLoader';
import { Nav } from 'react-bootstrap';
export default function LoginComponent() {
  const Login = useSelector((state) => state.loginuser.Login)
  const userId=useSelector((state) => state.loginuser)
  const token=useSelector((state)=>state.loginuser.token)
  const [loading,setLoading]=useState(false);
  const [logoutall,setLogoutAll]=useState(false);
  const dispatch= useDispatch();
  function Logout(type){
    let data =new FormData();
    data.append("userId",userId.userDetail.id)
    data.append("type",type)
    if(type==1){
      setLoading(true)
    }else{
      setLogoutAll(true)
    }
    fetch(BASE_URL+"logout", {
      method: "POST",
      headers: {
             'Accept': 'application/json',
             "Authorization": "Bearer "+token,
      },
      body:data
    })
      .then((response) => {
        if (response.status===200){
          setLoading(false);
          setLogoutAll(false);
          dispatch(logout());
        }
      })

      //Then with the data from the response in JSON...
    }
    useEffect(() => {
    }, [loading,logoutall])
    
    if(Login==null||Login==false){
      return  <Navigate to="/" replace={true} />
      }
  return (
    <>
      {Login ? 
     
        <Nav>
         <NavLink   className={({ isActive }) =>
              isActive ? "active" : " menutext"} to="/ProfilePage" >
            <i className="bi bi-person  me-2"></i>
            Profile
          </NavLink>
          <NavLink  className={({ isActive }) =>
              isActive ? "active" : " menutext"} to="/Password">
            <i className="bi bi-key me-2"></i>
            Change Password
          </NavLink>

          <Button variant="light" className="m-2 menubutton" onClick={()=>Logout(1)} disabled={logoutall}>
          {loading?<SmallLoader loadingtext={"Logging out..."}/>:"Logout"}
          </Button>
          <Button variant="light" className="m-2 menubutton" onClick={()=>Logout(2)} disabled={loading}>
          {logoutall?<SmallLoader loadingtext={"Logging out..."}/>:"Logout All"}
          </Button>

        </Nav>
      :
          null} 
    </>
  )
}
