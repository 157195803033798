import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { DateTime} from 'luxon';
import '../../css/List.css';
import TextField from '@mui/material/TextField';
import SortComponent from '../CommonComponents/SortComponent';
import { Link } from 'react-router-dom';

function DairyList(props) {
   const [value,setValue]=useState(null);
    return (
    <div className='mt-3 customer-list-table'>
        
     <Table striped bordered hover responsive>
         <thead>
            <tr className='tablehead'>
                <th>SNo</th>
                <th><SortComponent sort={props.sort} sortby={"customer_id"} />Customer Id </th>
                <th><SortComponent sort={props.sort} sortby={"customer_name"} />Customer Name </th>
                <th><SortComponent sort={props.sort} sortby={"telephone"} />Mobile </th>
                <th><SortComponent sort={props.sort} sortby={"id"} />Dairy Id </th>
                <th><SortComponent sort={props.sort} sortby={"name"} />Dairy Name </th>
                <th><SortComponent sort={props.sort} sortby={"description"} />Description</th>
                <th><SortComponent sort={props.sort} sortby={"dairycustomer_count"} />Customer Count</th>
                <th><SortComponent sort={props.sort} sortby={"customermilk_count"} />Milk Entries</th>
                <th><SortComponent sort={props.sort} sortby={"dairy_status"} /> Status</th>
                <th><SortComponent sort={props.sort} sortby={"billing_cycle"} />Billing Cycle</th>
                <th><SortComponent sort={props.sort} sortby={"steps"} /> Steps</th>
                <th><SortComponent sort={props.sort} sortby={"created_at"} />Created On</th>
                <th>Action</th>
            </tr>
            <tr className='text-center'>
                <th> </th>
                <th><input onChange={e=>props.searchParam(e.target.value,"customer_id")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"customer_name")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"telephone")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"id")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"name")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"description")}/></th>
                <th><div style={{width:"150px"}}></div></th>
                <th><div style={{width:"150px"}}></div></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"dairy_status")}/></th>
                <th>
                    <select className="form-select text-center" aria-label="Default select example" style={{width: "170px"}} onChange={e=>props.searchParam(e.target.value,"billing_cycle")}>
                                <option value="">All</option>
                                <option value="monthly">Monthly</option>
                                <option value="10 days">10 Days</option>
                    </select>
                </th>
                <th><input onChange={e=>props.searchParam(e.target.value,"steps")}/></th>
                <th><TextField type="date" className='text-center' onChange={e=>props.searchParam(e.target.value,"created_at")}/></th>
            </tr>
         </thead>
          <tbody>
            { 
                props.data.map((list,count) =>{
                    return( 
                    <tr className='text-center' key={list.id}>
                    <td>{++count}</td>
                    <td>{list.customer_id}</td>
                    {/* {console.log(list.customer_id,list.customer.firstname,list.customer.lastname)} */}
                    <td>{(list.customer?.firstname||list.customer?.lastname)? list.customer.firstname+" "+list.customer.lastname:"---"}</td>
                    <td>{(list.customer?.telephone)? list.customer.telephone:"---"}</td>
                    <td>{list.id}</td>
                    <td>{list.name}</td>
                    <td>{list.description?list.description:"---"}</td>
                    <td>{list.dairycustomer_count?list.dairycustomer_count:"--"}</td>
                    <td>{list.customermilk_count?list.customermilk_count:"--"}</td>
                    <td>{list.dairy_status}</td>
                    <td>{list.billing_cycle}</td>
                    <td>{list.steps}</td>
                    <td>{list.created_at?DateTime.fromISO(list.created_at).minus({hours: 5,minutes:30}).toFormat("dd-LLL-yyyy"):"--"}</td>
                    <td>
                        <Link to={`${list.id}`+"/"+`${list.name}`} >
                                    <button className='btn btn-outline-success' style={{width: "auto"}} >
                                        Rate Charts
                                    </button>
                        </Link>
                    </td>
                    </tr>
                    )})
            }
        </tbody>
        </Table>
   
    
  </div>
  )
}

export default DairyList