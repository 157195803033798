import React, { useEffect,useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loader from '../Components/CommonComponents/Loader';
import Navbarp from '../Components/CommonComponents/Navbarp'
import SideNav from '../Components/CommonComponents/SideNav'
import { BASE_URL } from '../Config/Config';
import { logout } from '../features/Loginuser';
import { DateTime } from 'luxon';
import {formatdate} from '../Common/Dateformat';
import { Navigate } from 'react-router-dom';
import DialogModal from '../Components/CommonComponents/DialogModal';
import SmallLoader from '../Components/CommonComponents/SmallLoader';
import JSONInput from 'react-json-editor-ajrm';
import locale    from 'react-json-editor-ajrm/locale/en';
import CouponDropDown from '../Components/Dropdown/CouponDropDown';

function OfferDetailpage() {
    const token=useSelector((state) => state.loginuser.token);
    const Login = useSelector((state) => state.loginuser.Login);
    let params = useParams();
    let dispatch=useDispatch();
    const [type,setType]=useState("");
    const [actionLoader,setActionLoader]=useState(false)
    const [loading,setLoading]=useState(true);
    const [statusloader,setStatusLoader]=useState(false)
    const [offerDetail,setOfferDetail]=useState(true);
    const [show,setShow]=useState(false);
    const [message,setMessage]=useState("");
    const [title,setTitle]=useState("")
    const [description,setDescription]=useState("")
    const [config,setConfig]=useState("")
    const [coupon_id,setCoupon_id]=useState(null)
    var fdata =new FormData();
      fdata.append("offer_id",params.offer_id)
    useEffect(() => {
          fetch(BASE_URL+"getOfferDetail", {
              method: "POST",
              headers: {
                //'X-XSRF-TOKEN': 'XSRF-TOKEN)',
                    'Accept': 'application/json',
                  "Authorization": "Bearer "+token,
              },
              body:fdata,
              
              })
              
              .then((response) => {
                if (response.status===401){
                  dispatch(logout());
                }else{
                  return response.json()
                }
              })
              //Then with the data from the response in JSON...
              .then((data) => {
                  setLoading(false);
                  setOfferDetail(data.offerDetail[0]);
                  setTitle(data.offerDetail[0].title);
                  setConfig(data.offerDetail[0].config);
                  setDescription(data.offerDetail[0].description)
                  setCoupon_id(data.offerDetail[0]?.coupon_id)
                  
                })
            },[setOfferDetail])
            
        function updatedata(e){
            e.preventDefault();
            setStatusLoader(true)
            var updatedata =new FormData();
            updatedata.append("offer_id",e.target.offer_id.value)
            updatedata.append("coupon_id",coupon_id)
            updatedata.append("title",JSON.stringify(title))
            updatedata.append("description",JSON.stringify(description))
            updatedata.append("config",JSON.stringify(config))
            updatedata.append("start_date",formatdate("startdate",e.target.start_date.value))
            updatedata.append("end_date",formatdate("enddate",e.target.end_date.value))
            updatedata.append("is_active",e.target.is_active.value)

            fetch(BASE_URL+"updateOfferDetail", {
                method: "POST",
                headers: {
                  //'X-XSRF-TOKEN': 'XSRF-TOKEN)',
                      'Accept': 'application/json',
                    "Authorization": "Bearer "+token,
                },
                body:updatedata,
                
                })
                
                .then((response) => {
                  if (response.status===401){
                    dispatch(logout());
                  }else{
                    return response.json()
                  }
                })
                //Then with the data from the response in JSON...
                .then((data) => {
                  setType("status");
                  setShow(true);
                  setMessage(data.message);
                  setActionLoader(false)
                  setStatusLoader(false)
                })
        }
  function modelAction(show){
    setShow(show);
  }
  if(Login==null||Login==false){
    return  <Navigate to="/" replace={true} />
    }    
  return (
    <>
    <DialogModal 
        show={show} 
        message={message} 
        action={modelAction} 
        id="" 
        type={type}
        actionLoader={actionLoader}
       />
      <div className="container-fluid ps-0 pe-0">
        <div className="row">
          <div> 
          <Navbarp />
          </div>
          <div>
            <h3 className='ms-3' style={{'margin-top':'200px'}}>Offer Detail</h3>
            {loading?
            <Loader/>:
                <form onSubmit={updatedata}>   
                    <div className='mt-4'>
                        <div className="row align-items-start ">
                        <div className="ms-3 mb-3 col-12 col-lg-3">
                                <label className="form-label">Offer Id</label>
                                <input type="number" name="offer_id" className="form-control" defaultValue={offerDetail?.offer_id} disabled />
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-6">
                                <label  className="form-label">Title</label>
                                <JSONInput
                                locale={ locale }
                                colors={{
                                          "background":"white",
                                          "default":"#000000",
                                          "keys":"red",
                                          "string":"#000000",
                                          "keys_whiteSpace":"red"
                                          
                                        }}
                                placeholder={offerDetail?.title}
                                height="auto"
                                onChange={(value)=>{setTitle(value.jsObject);console.log("value",value.jsObject)}}
                                />
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2">
                                <label  className="form-label">Attached Coupon</label>
                                <CouponDropDown defaultValue={offerDetail.coupon_id} coupon_id={setCoupon_id} type={"coupon_name"} column={"name"} />
                                        {console.log(coupon_id)}
                                <div className="form-text">.</div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className="row align-items-start ">
                        <div className="ms-3 mb-3 col-12 col-lg-6">
                                <label  className="form-label">Description</label>
                                <JSONInput
                               locale={ locale }
                                colors={{
                                          "background":"white",
                                          "default":"#000000",
                                          "keys":"red",
                                          "string":"#000000",
                                          "keys_whiteSpace":"red"
                                        }}
                                placeholder={offerDetail?.description}
                                height="auto"
                                width='auto'
                                reset={false}
                                onChange={(value)=>{setDescription(value.jsObject);console.log("value",value.jsObject)}}
                                />
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-5">
                                <label  className="form-label">Configuration</label>
                                <JSONInput
                               locale={ locale }
                                colors={{
                                          "background":"white",
                                          "default":"#000000",
                                          "keys":"red",
                                          "string":"#000000",
                                          "keys_whiteSpace":"red",
                                         
                                        }}
                                placeholder={offerDetail?.config}
                                height="auto"
                                width='auto'
                                onChange={(value)=>{setConfig(value.jsObject);console.log("value",value.jsObject)}}
                                />
                                <div className="form-text">Offer End Date.</div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className="row align-items-start ">
                        <div className="ms-3 mb-3 col-12 col-lg-6">
                                <label  className="form-label">Start Date</label>
                                <input type="date"  name="start_date" className="form-control" defaultValue={DateTime.fromSQL(offerDetail?.start_date).toFormat("yyyy-LL-dd")} />
                                <div className="form-text">Offer Start Date.</div>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-5">
                                <label  className="form-label">End Date</label>
                                <input type="date"  name="end_date" className="form-control" defaultValue={DateTime.fromSQL(offerDetail?.end_date).toFormat("yyyy-LL-dd")}  />
                                <div className="form-text">Offer End Date.</div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className="row align-items-start ">
                        <div className="ms-3 mb-3 col-12 col-lg-6">
                                <label  className="form-label">Active Status</label>
                                <select className="form-select text-right" aria-label="Default select example" name="is_active" defaultValue={offerDetail?.is_active}>
                                      <option  value="">Select Status</option>
                                      <option value="1">Active</option>
                                      <option value="0">Inactive</option>
                                </select>
                                <div className="form-text"></div>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-5">
                                <label  className="form-label">Date Added</label>
                                <input type="text"  name="date_added" className="form-control" defaultValue={DateTime.fromISO(offerDetail?.date_added).toFormat("yyyy-LL-dd hh:mm a")} disabled />
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="ms-3 btn btn-warning mt-3 btn-lg" >
                    {statusloader?<SmallLoader loadingtext={"Submitting..."}/>:"Submit"}
                    </button>
                </form>   
                  }    
          </div> 
        
        </div>
      </div>
  </>
  )
}

export default OfferDetailpage