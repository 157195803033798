import React from 'react';
import Table from 'react-bootstrap/Table';
import { DateTime} from 'luxon';
import '../../css/List.css';
import TextField from '@mui/material/TextField';
import SortComponent from '../CommonComponents/SortComponent';
import { Outlet, Link } from 'react-router-dom';
import { LITER_API_BASE_URL, LITER_BASE_URL } from '../../Config/Config';
import { useSelector } from 'react-redux';

function CustomerList(props) {
   
    const token = useSelector((state) => state.loginuser.token);

    const loginToCustomer = async (customerId) => {
        const response = await fetch(LITER_API_BASE_URL + "v1/admin/customerLogin", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "token": token,
                "customer_id": customerId
            })
        })  
        .then((response) => {
            if (response.status === 401){
                alert("401 UNAUTHORIZED");
            } else if (response.status === 400){
                alert("400 Bad request");
            } else if (response.status === 200){
                return response.json()
            } else {
                alert("Server error");
            }
        });

        if(response.admin_token) {
            window.open(`${LITER_BASE_URL}?admin_token=${response.admin_token}&login=admin`, "__blank");
        } else {
            alert("Server error");
        }
    };

    return ( 
    <div className='mt-3 customer-list-table'>    
     <Table striped bordered hover responsive>
         <thead>
            <tr className='tablehead' >
                <th>SNo</th>
                <th><SortComponent sort={props.sort} sortby={"customer_id"} />Customer Id</th>
                <th><SortComponent sort={props.sort} sortby={"firstname"} />First Name</th>
                <th><SortComponent sort={props.sort} sortby={"lastname"}/>Last Name</th>
                <th>Customer Type</th>
                <th><SortComponent sort={props.sort} sortby={"telephone"}/>Mobile</th>
                <th><SortComponent sort={props.sort} sortby={"date_added"}/>Acount Created </th>
                <th><SortComponent sort={props.sort} sortby={"email"}/>Email</th>
                <th><SortComponent sort={props.sort} sortby={"dob"}/>DOB</th>
                <th><SortComponent sort={props.sort} sortby={"email"}/>Gender</th>
                <th><SortComponent sort={props.sort} sortby={"ip"}/>IP</th>
                <th><SortComponent sort={props.sort} sortby={"last_login"}/>Last Login</th>
                <th><SortComponent sort={props.sort} sortby={"customer_id"}/>Status</th>
                <th>Login Attempts</th>
                <th>Blocked Upto</th>
                <th>Action</th>
            </tr>
            <tr className='text-center'>
                <th> </th>
                <th><input onChange={e=>props.searchParam(e.target.value,"customer_id")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"firstname")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"lastname")}/></th>
                <th> 
                    <select className="form-select text-center" aria-label="Default select example" style={{width: "170px"}} onChange={e=>props.searchParam(e.target.value,"customer_group_id")}>
                            <option  value="">All</option>
                            <option value="1">Kisan</option>
                            <option value="2">Dairy Owner</option>
                        </select>
                </th>
                <th><input onChange={e=>props.searchParam(e.target.value,"telephone")}/></th>
                <th><TextField type="date" className='text-center' style={{width: "170px"}} onChange={e=>props.searchParam(e.target.value,"date_added")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"email")}/></th>
                <th><TextField type="date" className='text-center' onChange={e=>props.searchParam(e.target.value,"dob")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"gender")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"ip")}/></th>
                <th><TextField type="date" className='text-center' onChange={e=>props.searchParam(e.target.value,"last_login")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"status")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"login_attempts")}/></th>
                <th ><TextField type="date" className='text-center' onChange={e=>props.searchParam(e.target.value,"blocked_up_to")}/></th>
            </tr>
         </thead>
          <tbody>
            {  
                props.data.map((list,count) =>{
                     
                    return(
                    <tr className='text-center' key={list.customer_id} >
                    <td>{++count}</td>
                    <td>{list.customer_id}</td>
                    <td>{list.firstname}</td>
                    <td>{list.lastname}</td>
                    <td>{list.customer_group_id==1 ? "Kisan":"Dairy Owner"}</td>
                    <td>{list.telephone}</td>
                    <td>{DateTime.fromSQL(list.date_added).toFormat("dd-LLL-yyyy")}</td>
                    <td>{list.email? list.email:"---" }</td>
                    <td>{list.dob?DateTime.fromSQL(list.date_added).toFormat("dd-LLL-yyyy"):"---"}</td>
                    <td>{list.gender? list.gender:"---"}</td>
                    <td>{list.ip}</td>
                    <td>{list.last_login?DateTime.fromSQL(list.last_login).toFormat("dd-LLL-yyyy hh:mm a"):"---"}</td>
                    <td>{list.status}</td>
                    <td>{list.login_attempts}</td>
                    <td>{list.blocked_up_to?DateTime.fromSQL(list.blocked_up_to).minus({hours: 5,minutes:30}).toFormat("dd-LLL-yyyy"):"--"}</td>
                    <td><div style={{width: "285px"}}>
                            <Link to={`/customers/details/${list.customer_id}`} >
                                <button className='btn btn-outline-dark ms-2 mt-1' style={{width: "50px"}} >
                                    <i className="bi bi-person-lines-fill"/>
                                </button>
                            </Link>
                            <Link to={`/customers/${list.customer_id}`} >
                                <button className='btn btn-outline-success ms-1  mt-1' style={{width: "50px"}} >
                                    <i className="bi bi-pen me-1"/>
                                </button>
                            </Link>
                            <button className='btn btn-outline-secondary  mt-1 ms-2' style={{width: "100px"}} onClick={e=> loginToCustomer(list.customer_id)}  >
                                    <i className="bi bi-person"></i> Login
                            </button>
                            <button className='btn btn-outline-danger mt-1 ms-2' style={{width: "50px"}} onClick={e=>props.returnCustomerId(list.customer_id)}  >
                                    <i className="bi bi-trash3"></i>
                            </button>
                            
                        </div>
                    </td>
                    </tr>)})
            }
        </tbody>
        </Table>
   
        <Outlet/>
  </div>
 
  )
}

export default CustomerList