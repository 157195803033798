import React, { useEffect,useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loader from '../Components/CommonComponents/Loader';
import Navbarp from '../Components/CommonComponents/Navbarp'
import SideNav from '../Components/CommonComponents/SideNav'
import { BASE_URL } from '../Config/Config';
import { logout } from '../features/Loginuser';
import { DateTime } from 'luxon';
import {formatdate} from '../Common/Dateformat';
import { Navigate } from 'react-router-dom';
import DialogModal from '../Components/CommonComponents/DialogModal';
import SmallLoader from '../Components/CommonComponents/SmallLoader';
import CustomerDropDown from '../Components/Dropdown/CustomerDropDown';
import PlanDropDown from '../Components/Dropdown/PlanDropDown';
import DurationDropDown from '../Components/Dropdown/DurationDropDown';

function CouponDetailpage() {
    const token=useSelector((state) => state.loginuser.token);
    const Login = useSelector((state) => state.loginuser.Login);
    let params = useParams();
    let dispatch=useDispatch();
    const [type,setType]=useState("");
    const [actionLoader,setActionLoader]=useState(false)
    const [loading,setLoading]=useState(true);
    const [statusloader,setStatusLoader]=useState(false)
    const [coupondetail,setCoupoDetail]=useState(true);
    const [show,setShow]=useState(false);
    const [message,setMessage]=useState("");
    const [customer,setCustomer]=useState([])
    const [plan,setPlan]=useState(null);
    const [planDuration,setPlanDuration]=useState(null)
    const [couponplandetail,setcouponplandetail]=useState(null)
    const [disabled,setDisabled]=useState(true)
    var fdata =new FormData();

      fdata.append("coupon_id",params.coupon_id)
    useEffect(() => {
          fetch(BASE_URL+"getcoupondetail", {
              method: "POST",
              headers: {
                //'X-XSRF-TOKEN': 'XSRF-TOKEN)',
                    'Accept': 'application/json',
                  "Authorization": "Bearer "+token,
              },
              body:fdata,
              
              })
              
              .then((response) => {
                if (response.status===401){
                  dispatch(logout());
                }else{
                  
                  return response.json()
                }
              })
              //Then with the data from the response in JSON...
              .then((data) => {
                  console.log(data);
                  setLoading(false);
                  setCoupoDetail(data.coupondetail[0]);
                  setcouponplandetail(data.couponplandetail[0])
                  setPlan(data.couponplandetail[0].plan_id);
                  setPlanDuration(data.couponplandetail[0].plan_duration);
                })
            },[setCoupoDetail])
            
        function updatedata(e){
            e.preventDefault();
            setStatusLoader(true)
            var updatedata =new FormData(e.target);
            updatedata.set("start_date",formatdate("startdate",updatedata.get("start_date"),));
            updatedata.set("end_date",formatdate("enddate",updatedata.get("end_date"),));
            updatedata.append("coupon_id",e.target.coupon_id.value)
            updatedata.append("plan_id",plan)
            updatedata.append("plan_duration",planDuration)
            updatedata.append("customer",JSON.stringify(customer))
            fetch(BASE_URL+"updatecoupondetail", {
                method: "POST",
                headers: {
                  //'X-XSRF-TOKEN': 'XSRF-TOKEN)',
                      'Accept': 'application/json',
                    "Authorization": "Bearer "+token,
                },
                body:updatedata,
                
                })
                
                .then((response) => {
                  if (response.status===401){
                    dispatch(logout());
                  }else{
                    return response.json()
                  }
                })
                //Then with the data from the response in JSON...
                .then((data) => {
                  setType("status");
                  setShow(true);
                  setMessage(data.message);
                  setActionLoader(false)
                  setStatusLoader(false)
                })
        }
  function modelAction(show){
    setShow(show);
  }
  const onchangefunction=setPlan;
 // console.log("onchangefunction",onchangefunction)
  if(Login==null||Login==false){
    return  <Navigate to="/" replace={true} />
    }   
    // const id = customer.map((data)=>{return data.value}  ) ; 
    // console.log("id",id)
  return (
    <>
    <DialogModal 
        show={show} 
        message={message} 
        action={modelAction} 
        id="" 
        type={type}
        actionLoader={actionLoader}
       />
      <div className="container-fluid ps-0 pe-0">
        <div className="row">
          <div>
           <Navbarp />
          </div>
          <div>
            <h3 className='ms-3' style={{'margin-top':'200px'}}>Coupon Detail</h3>
            {loading?
            <Loader/>:
                <form onSubmit={updatedata}>   
                    <div className='mt-4'>
                        <div className="row align-items-start ">
                        <div className="ms-3 mb-3 col-12 col-lg-2">
                                <label className="form-label">Coupon Id</label>
                                <input type="number" name="coupon_id" className="form-control" defaultValue={coupondetail.coupon_id} disabled={true} />
                                <div id="emailHelp" className="form-text">Id of the Coupon.</div>
                            </div>
                            
                            <div className="ms-3 mb-3 col-12 col-lg-2">
                                <label  className="form-label">Name</label>
                                <input type="text" name="name" className="form-control" defaultValue={coupondetail.name} />
                                <div id="emailHelp" className="form-text">Name of Coupon.</div>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2">
                                <label  className="form-label">Code</label>
                                <input type="text"  name="code" className="form-control" defaultValue={coupondetail.code} disabled={disabled} />
                                <div className="form-text">Code of Coupon.</div>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2">
                                <label className="form-label">type</label>
                                <select className="form-select text-right" aria-label="Default select example" name="type" defaultValue={coupondetail.type}>
                                      <option  value="">Select Type</option>
                                      <option value="P">Percentage</option>
                                      <option value="F">Flat</option>
                                  </select>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-3">
                                <label  className="form-label">Select Customer </label>
                                <CustomerDropDown onchange={setCustomer} />
                            </div>  
                            
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className="row align-items-start ">
                        <div className="ms-3 mb-3 col-12 col-lg-2">
                                    <label  className="form-label">Discount</label>
                                <input type="text"  name="discount" className="form-control" defaultValue={coupondetail.discount} />
                                <div id="emailHelp" className="form-text">Email of Customer.</div>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2">
                                <label className="form-label">Plan</label>
                                {/* <input type="text" name="plan" className="form-control" defaultValue={coupondetail.plan} /> */}
                                <PlanDropDown action={setPlan} defaultValue={couponplandetail?.plan_id} />
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2">
                                <label className="form-label">Plan Duration</label>
                                {/* <input type="text" name="plan" className="form-control" defaultValue={coupondetail.planduratiuon} /> */}
                                <DurationDropDown action={setPlanDuration} defaultValue={couponplandetail?.plan_duration}/>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2">
                                <label  className="form-label">Start Date</label>
                                <input type="date"  name="start_date" className="form-control" defaultValue={DateTime.fromSQL(coupondetail.start_date).toFormat("yyyy-LL-dd")} />
                                <div className="form-text">Offer Start Date.</div>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-3">
                                <label  className="form-label">End Date</label>
                                <input type="date"  name="end_date" className="form-control" defaultValue={DateTime.fromSQL(coupondetail.end_date).toFormat("yyyy-LL-dd")}  />
                                <div className="form-text">Offer End Date.</div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className="row align-items-start ">
                        <div className="ms-3 mb-3 col-12 col-lg-2">
                                <label  className="form-label">Total Uses</label>
                                <input type="text"  name="uses_total" className="form-control" defaultValue={coupondetail.uses_total} />
                                <div className="form-text">No of Total Uses of Coupon</div>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2">
                                <label  className="form-label">Uses Per Customer</label>
                                <input type="number"  name="uses_customer" className="form-control" defaultValue={coupondetail.uses_customer} />
                                <div id="emailHelp" className="form-text">No of Times Coupon Can be Used by Customer.</div>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2">
                                <label  className="form-label">Active Status</label>
                                <select className="form-select text-right" aria-label="Default select example" name="is_active" defaultValue={coupondetail.is_active}>
                                      <option  value="">Select Status</option>
                                      <option value="1">Active</option>
                                      <option value="0">Inactive</option>
                                  </select>
                                <div className="form-text"></div>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-4">
                                <label  className="form-label">Date Added</label>
                                <input type="text"  name="date_added" className="form-control" defaultValue={DateTime.fromISO(coupondetail.date_added).toFormat("yyyy-LL-dd hh:mm a")} disabled />
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="ms-3 btn btn-warning mt-3 btn-lg" >
                    {statusloader?<SmallLoader loadingtext={"Submitting..."}/>:"Submit"}
                    </button>
                </form>   
                  }    
          </div> 
        
        </div>
      </div>
  </>
  )
}

export default CouponDetailpage