import { createSlice } from "@reduxjs/toolkit";
export const coupon=createSlice({
    name :"coupon",
    initialState:{
        coupon:JSON.parse(localStorage.getItem('coupon')),
    },
    reducers:{
        setCoupanData:(state,action)=>{
            state.coupon=action.payload;
            localStorage.setItem('coupon',JSON.stringify(state.coupon));
        }
    }

})
export const {setCoupanData} =coupon.actions;
export default coupon.reducer;