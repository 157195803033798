import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { DateTime} from 'luxon';
import '../../css/List.css';
import TextField from '@mui/material/TextField';
import SortComponent from '../CommonComponents/SortComponent';
import { Link } from 'react-router-dom';


function PredefinedRateList(props) {
   const [value,setValue]=useState(null);
    return (
    <div className='mt-3 customer-list-table'>
        
     <Table striped bordered hover responsive>
         <thead>
            <tr className='tablehead'>
                <th>SNo</th>
                <th>Rate List Id <SortComponent sort={props.sort} sortby={"rate_list_id"} /></th>
                <th>Name<SortComponent sort={props.sort} sortby={"name"} /></th>
                <th>Description<SortComponent sort={props.sort} sortby={"description"} /></th>
                <th>Rate Chart Setup Type<SortComponent sort={props.sort} sortby={"rate_chart_setup_type"} /> </th>
                <th>Configuration <SortComponent sort={props.sort} sortby={"configuration"} /></th>
                <th>Date Added <SortComponent sort={props.sort} sortby={"created_at"} /></th>
                <th>Date Modified<SortComponent sort={props.sort} sortby={"modified_at"} /></th>
                <th>Action</th>
            </tr>
            <tr className='text-center'>
                <th> </th>
                <th><input onChange={e=>props.searchParam(e.target.value,"rate_list_id")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"name")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"description")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"rate_chart_setup_type")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"configuration")}/></th>
                <th><TextField type="date" className='text-center' onChange={e=>props.searchParam(e.target.value,"created_at")}/></th>
                <th><TextField type="date" className='text-center' onChange={e=>props.searchParam(e.target.value,"modified_at")}/></th>
            </tr>
         </thead>
          <tbody>
            { 
                props.data.map((list,count) =>{
                    return( 
                    <tr className='text-center' key={list.rate_list_id}>
                    <td>{++count}</td>
                    <td>{list.rate_list_id}</td>
                    <td>{list.name}</td>
                    <td>{list.description}</td>
                    <td>{list.rate_chart_setup_type}</td>
                    <td>
                        <button className='btn btn-outline-success d-block' onClick={() =>  navigator.clipboard.writeText(JSON.stringify(list.configuration))}>
                                <i className="bi bi-clipboard"/>
                        </button>
                        {JSON.stringify(list.configuration)}
                    </td>
                    <td>{list.created_at?DateTime.fromISO(list.created_at).toFormat("dd-LLL-yyyy"):"--"}</td>
                    <td>{list.modified_at?DateTime.fromISO(list.modified_at).toFormat("dd-LLL-yyyy"):"--"}</td>
                    <td>
                        <div style={{width: "150px"}}>
                            <Link to={`/ratelist/${list.rate_list_id}`}  >
                                <button className='btn btn-outline-success' style={{width: "50px"}} >
                                        <i className="bi bi-pen me-1"/>
                                </button>
                            </Link>
                            <button className='btn btn-outline-danger ms-2' style={{width: "50px"}} onClick={e=>props.returnratelist_id(list.rate_list_id)}  >
                                <i className="bi bi-trash3"></i>
                            </button>
                        </div>
                    </td>
                    </tr>
                    )})
            }
             
        </tbody>
        </Table>
       
    
  </div>
  )
}

export default PredefinedRateList