import React, { useEffect,useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Navbarp from '../Components/CommonComponents/Navbarp'
import SideNav from '../Components/CommonComponents/SideNav'
import { BASE_URL } from '../Config/Config';
import { logout } from '../features/Loginuser';
import { Navigate } from 'react-router-dom';
import {formatdate} from '../Common/Dateformat';
import '../css/List.css'
import DialogModal from '../Components/CommonComponents/DialogModal';
import SmallLoader from '../Components/CommonComponents/SmallLoader';
import CustomerDropDown from '../Components/Dropdown/CustomerDropDown';
import PlanDropDown from '../Components/Dropdown/PlanDropDown';
import DurationDropDown from '../Components/Dropdown/DurationDropDown';

function AddCouponPage() {
    const token=useSelector((state) => state.loginuser.token);
    const Login = useSelector((state) => state.loginuser.Login);
    let dispatch=useDispatch();
    const [show,setShow]=useState(false);
    const [message,setMessage]=useState("");
    const [type,setType]=useState("")
    const [actionLoader,setActionLoader]=useState(false)
    const [loading,setLoading]=useState(false)
    const [customer,setCustomer]=useState([])
    const [plan,setPlan]=useState(null);
    const [planDuration,setPlanDuration]=useState(null)

    getcustomerlist();    
    function updatedata(e){
            setLoading(true);
            e.preventDefault();
            //console.log(e)
            var coupondata =new FormData(e.target);
            coupondata.set("start_date",formatdate("startdate",coupondata.get("start_date"),));
            coupondata.set("end_date",formatdate("enddate",coupondata.get("end_date"),));
            coupondata.append("plan_id",plan)
            coupondata.append("plan_duration",planDuration)
            fetch(BASE_URL+"addnewcoupon", {
                method: "POST",
                headers: {
                  //'X-XSRF-TOKEN': 'XSRF-TOKEN)',
                      'Accept': 'application/json',
                    "Authorization": "Bearer "+token,
                },
                body:coupondata,
                
                })
                
                .then((response) => {
                  if (response.status===401){
                    dispatch(logout());
                  }else{
                    return response.json()
                  }
                })
                //Then with the data from the response in JSON...
                .then((data) => {
                  setType("status");
                  setShow(true);
                  setMessage(data.message);
                  setActionLoader(false)
                  setLoading(false);
                  e.target.reset();
                })
                
              }
  function getcustomerlist(){
      fetch(BASE_URL+"customerlist", {
          method: "GET",
          headers: {
            //'X-XSRF-TOKEN': 'XSRF-TOKEN)',
                'Accept': 'application/json',
              'Content-Type': 'application/json',
              "Authorization": "Bearer "+token,
          }

          })
          
          .then((response) => {
            if (response.status===401){
              dispatch(logout());
            }else{
              return response.json()
            }
          })
          //Then with the data from the response in JSON...
          .then((data) => {
              
          })

  }
  function modelAction(show){
    setShow(show);
    }      
  if(Login==null||Login==false){
    return  <Navigate to="/" replace={true} />
    }    
  return (
    <>
    
    <DialogModal 
        show={show} 
        message={message} 
        action={modelAction} 
        id="" 
        type={type}
        actionLoader={actionLoader}
       />
      <div className="container-fluid ps-0 pe-0">
        <div className="row ps-0 pe-0">
          <div>  
            <Navbarp />
          </div>
          <div>
            <h3 className='ms-3' style={{'margin-top':'200px'}}>Coupon Detail</h3>
                
                <form onSubmit={updatedata} name="couponform">   
                    <div className='mt-4'>
                        <div className="row align-items-start ">
                        <div className="ms-3 mb-3 col-12 col-lg-2">
                                <label  className="form-label">Name</label>
                                <input type="text" name="name" className="form-control" required />
                                <div id="emailHelp" className="form-text">Name of Coupon.</div>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2">
                                <label  className="form-label">Code</label>
                                <input type="text"  name="code" className="form-control" required />
                                <div className="form-text">Code of Coupon.</div>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2">
                                <label  className="form-label">Select Customer </label>
                                <CustomerDropDown setCustomer={setCustomer}/>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2">
                                <label className="form-label">Coupon Type</label>
                                  <select className="form-select text-right" aria-label="Default select example" name="type" required>
                                      <option  value="">Select Type</option>
                                      <option value="P">Percentage</option>
                                      <option value="F">Flat</option>
                                  </select>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-3">
                                <label  className="form-label">Discount</label>
                                <input type="text"  name="discount" className="form-control" required/>
                            </div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className="row align-items-start ">
                           
                        <div className="ms-3 mb-3 col-12 col-lg-4">
                                <label className="form-label">Plan</label>
                                <PlanDropDown action={setPlan}  />
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-3">
                                <label className="form-label">Plan Duration</label>
                                <DurationDropDown action={setPlanDuration}/>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2">
                                <label  className="form-label">Start Date</label>
                                <input type="date"  name="start_date" className="form-control" required/>
                                <div className="form-text">Offer Start Date.</div>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-2">
                                <label  className="form-label">End Date</label>
                                <input type="date"  name="end_date" className="form-control" required  />
                                <div className="form-text">Offer End Date.</div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className="row align-items-start ">
                        <div className="ms-3 mb-3 col-12 col-lg-3">
                                <label  className="form-label">Total Uses</label>
                                <input type="text"  name="uses_total" className="form-control" required />
                                <div className="form-text">No of Total Uses of Coupon</div>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-4">
                                <label  className="form-label">Uses Per Customer</label>
                                <input type="number"  name="uses_customer" className="form-control" required />
                                <div id="emailHelp" className="form-text">No of Times Coupon Can be Used by Customer.</div>
                            </div>
                            <div className="ms-3 mb-3 col-12 col-lg-4">
                                <label  className="form-label">Active Status required</label>
                                <select className="form-select text-right" aria-label="Default select example" name="is_active" required>
                                      <option  value="">Select Status</option>
                                      <option value="1">Active</option>
                                      <option value="0">Inactive</option>
                                  </select>
                                <div className="form-text"></div>
                            </div>
                        </div>
                    </div>
                    <button type="submit" className=" ms-3 btn btn-warning mt-3 btn-lg" >
                    {loading?<SmallLoader loadingtext={"Submitting..."}/>:"Submit"}
                    </button>
                </form>  
          </div> 
          
        </div>
      </div>
  </>
  )
}

export default AddCouponPage