import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { DateTime} from 'luxon';
import '../../css/List.css';
import TextField from '@mui/material/TextField';
import SortComponent from '../CommonComponents/SortComponent';
import {Link} from 'react-router-dom';


function PlanList(props) {
   const [value,setValue]=useState(null);
    return (
    <div className='mt-3 customer-list-table'>
        
     <Table striped bordered hover responsive>
         <thead>
            <tr className='tablehead'>
                <th>SNo</th>
                <th>Plan Id <SortComponent sort={props.sort} sortby={"plan_id"} /></th>
                <th>Name<SortComponent sort={props.sort} sortby={"name"} /></th>
                <th>Name Local English</th>
                <th>Name Local Hindi</th>
                <th>Description<SortComponent sort={props.sort} sortby={"description"} /></th>
                <th>Priority<SortComponent sort={props.sort} sortby={"priority"} /> </th>
                <th>Is last <SortComponent sort={props.sort} sortby={"is_last"} /></th>
                <th>Is Trial <SortComponent sort={props.sort} sortby={"is_trial"} /></th>
                <th>Show On UI<SortComponent sort={props.sort} sortby={"show_on_ui"} /></th>
                <th>Show On Feature List<SortComponent sort={props.sort} sortby={"show_on_feature_list"} /></th>
                <th>Daily Price<SortComponent sort={props.sort} sortby={"daily_price"} /></th>
                <th>Monthly Price<SortComponent sort={props.sort} sortby={"monthly_price"} /></th>
                <th>Quaterly Price<SortComponent sort={props.sort} sortby={"quarterly_price"} /></th>
                <th>Half yearly Price<SortComponent sort={props.sort} sortby={"half_yearly_price"} /></th>
                <th>Anual Price<SortComponent sort={props.sort} sortby={"annually_price"} /></th>
                <th>Active<SortComponent sort={props.sort} sortby={"is_active"} /></th>
                <th>Date Added <SortComponent sort={props.sort} sortby={"date_added"} /></th>
                <th>Date Modified<SortComponent sort={props.sort} sortby={"date_modified"} /></th>
                <th>Action</th>
            </tr>
            <tr className='text-center'>
                <th> </th>
                <th><input onChange={e=>props.searchParam(e.target.value,"plan_id")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"name")}/></th>
                <th></th>
                <th></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"description")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"priority")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"is_last")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"is_trial")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"show_on_ui")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"show_on_feature_list")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"daily_price")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"monthly_price")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"quarterly_price")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"half_yearly_price")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"annually_price")}/></th>
                <th><input onChange={e=>props.searchParam(e.target.value,"is_active")}/></th>
                <th><TextField type="date" className='text-center' onChange={e=>props.searchParam(e.target.value,"date_added")}/></th>
                <th><TextField type="date" className='text-center' onChange={e=>props.searchParam(e.target.value,"date_modified")}/></th>
                <th></th>
            </tr>
         </thead>
          <tbody>
            { 
                props.data.map((list,count) =>{
                    return( 
                    <tr className='text-center' key={list.plan_id}>
                    <td>{++count}</td>
                    <td>{list.plan_id}</td>
                    <td>{list.name}</td>
                    <td>{list.name_local?.en}</td>
                    <td>{list.name_local?.hi}</td>
                    <td>{list.description}</td>
                    <td>{list.priority}</td>
                    <td>{list.is_last}</td>
                    <td>{list.is_trial}</td>
                    <td>{list.show_on_ui}</td>
                    <td>{list.show_on_feature_list}</td>
                    <td>{list.daily_price}</td>
                    <td>{list.monthly_price}</td>
                    <td>{list.quarterly_price}</td>
                    <td>{list.half_yearly_price}</td>
                    <td>{list.annually_price}</td>
                    <td>{list.is_active}</td>
                    <td>{list.date_added?DateTime.fromISO(list.date_added).toFormat("dd-LLL-yyyy"):"--"}</td>
                    <td>{list.date_modified?DateTime.fromISO(list.date_modified).toFormat("dd-LLL-yyyy"):"--"}</td>
                    <td>
                        <div style={{width: "150px"}}>
                                <Link to={`/plans/${list.plan_id}`}  >
                                    <button className='btn btn-outline-success' style={{width: "50px"}} >
                                            <i className="bi bi-pen me-1"/>
                                        </button>
                                </Link>
                                <button className='btn btn-outline-danger ms-2' style={{width: "50px"}} onClick={e=>props.confirmPlanId(list.plan_id)}  >
                                <i className="bi bi-trash3"></i>
                                </button>
                         </div>
                    </td>
                    </tr>)})
            }
        </tbody>
        </Table>
   
    
  </div>
  )
}

export default PlanList