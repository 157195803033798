import React from 'react'
import { TextField, Grid } from '@mui/material';
import { formatdate } from '../../Common/Dateformat';
import { useState } from 'react';
import PagesNumber from './PagesNumber';
import Form from 'react-bootstrap/Form';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import '../../css/Calendar.css'
function Calendar(props) {
    const [startDateValue, setStartDateValue]=useState(DateTime.now());
    const [endDateValue,setEndDateValue]=useState(DateTime.now());
  return (
   
        <Grid container className='d-md-flex justify-content-start w-100' >
                                    <div className="ms-2 mt-3 me-4">
                                      <DatePicker
                                        label="Start Date"
                                        disableMaskedInput={true}
                                        value={startDateValue}
                                        maxDate={endDateValue}
                                        inputFormat="dd-MMM-yyyy"
                                        disableFuture={true}
                                        onChange={(newValue) => {
                                          setStartDateValue(newValue);
                                          props.searchBy.startdate=newValue.toUTC().startOf('day').toSeconds();
                                          props.setSearchBy({...props.searchBy})
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                      />
                                    </div>
                                    <div className="ms-2 mt-3 me-4">
                                      <DatePicker
                                        label="End Date"
                                        disableMaskedInput={true}
                                        value={endDateValue}
                                        minDate={startDateValue}
                                        inputFormat="dd-MMM-yyyy"
                                        disableFuture={true}
                                        onChange={(newValue) => {
                                          setEndDateValue(newValue);
                                          props.searchBy.enddate=newValue.toUTC().endOf('day').toSeconds();
                                          props.setSearchBy({...props.searchBy})
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                      />   
                                    </div>                    
                                    {props.type!=="dashboard"?
                                      <>
                                      <TextField className="ms-3 mt-3 fw-bold text-center me-3" 
                                                    style={{width:"80px"}} 
                                                    type="number" 
                                                    label="Records"
                                                    value={props.searchBy.records} 
                                                    InputLabelProps={{
                                                      shrink: true,
                                                    }}
                                                    onChange={e=>{
                                                      props.searchBy.records=e.target.value
                                                      props.setSearchBy({...props.searchBy})
                                                      }}>
                                      </TextField> 
                                      <Grid item className='ms-3 mt-4 me-5'>
                                          <PagesNumber lastPage={props.lastPage} currentPage={props.currentPage} setpage={props.setpage}/>
                                      </Grid>
                                      <Grid item className='ms-3 mt-4 me-5'>
                                          <Form.Select size="sm" className='w-10' onChange={(e)=>props.setSortingBy(e.target.value)}>
                                            <option value="1" >Sorting By Server </option>
                                            <option value="2" >Sorting By Browser </option>
                                          </Form.Select>  
                                      </Grid>
                                    </>:null
                                    }
                          </Grid>

  )
}

export default Calendar