import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Navbarp from '../Components/CommonComponents/Navbarp';
import SideNav from '../Components/CommonComponents/SideNav';
import { TextField, Grid } from '@mui/material';
import { useState,useEffect } from 'react';
import PagesNumber from '../Components/CommonComponents/PagesNumber';
import {BASE_URL} from '../Config/Config';
import { logout } from '../features/Loginuser';
import { setCoupanData } from '../features/Coupon';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Form from 'react-bootstrap/Form';
import '.././css/List.css'
import '.././css/Card.css'
import '.././css/Loader.css'
import { formatdate } from '../Common/Dateformat';
import CouponList from '../Components/List/CouponList';
import Loader from '../Components/CommonComponents/Loader';
import { Link,useMatch } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DialogModal from '../Components/CommonComponents/DialogModal';
import Calendar from '../Components/CommonComponents/Calendar';
import OfferList from '../Components/List/OfferList';

function Offers() {
  const dispatch=useDispatch();
  const [startdate, setstartdate] =useState("");
  const[enddate,setenddate]=useState("");
  const [list, setList] = useState([]);
  const [loading,setLoading]=useState(true);
  const Login = useSelector((state) => state.loginuser.Login);
  const token=useSelector((state) => state.loginuser.token);
  const [lastPage, setLastPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const[totalOffer,setTotalOffer]=useState(0) 
  const [count, setCount] = useState(0);
  const [sortingby,setSortingBy]=useState(1);
  const [show,setShow]=useState(false);
  const [message,setMessage]=useState("");
  const [coupon_id,setCoupon_id]=useState(null);
  const [type,setType]=useState("")
  const [actionLoader,setActionLoader]=useState(false)
  const [firstLoading,setFirstLoading]=useState(true)
  const [searchBy,setSearchBy]=useState({
      "records":100,
      "startdate":"",
      "enddate":""
    });
  
  useEffect(() => {
    if(firstLoading){
      getofferlist();
      setFirstLoading(false)
    }else{
      const getData = setTimeout(() => {
        getofferlist();
      }, 500)
      return () => clearTimeout(getData)
    }
    
  }, [currentPage,searchBy,show])
  function getofferlist(){
    let searchstr=searchstring(searchBy);
    fetch(BASE_URL+"offerlist?page="+currentPage+"&"+searchstr, {
        method: "GET",
        headers: {
          //'X-XSRF-TOKEN': 'XSRF-TOKEN)',
              'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer "+token,
        }

        })
        
        .then((response) => {
          if (response.status===401){
            dispatch(logout());
          }else{
            return response.json()
          }
        })
        //Then with the data from the response in JSON...
        .then((data) => {
            setLoading(false)
            setList(data.result.data);
            setLastPage(data.result.last_page);
            setCurrentPage(data.result.current_page);
            setTotalOffer(data.totalOffer);
            setCount(data.count)
            
        })
  }
  function deleteOffer(offer_id){
    var fdata =new FormData();
    fdata.append("offer_id",offer_id)
      fetch(BASE_URL+"deleteOffer", {
        method: "POST",
        headers: {
          //'X-XSRF-TOKEN': 'XSRF-TOKEN)',
              'Accept': 'application/json',
            "Authorization": "Bearer "+token,
        },
        body:fdata,
        })
        
        .then((response) => {
          if (response.status===401){
            dispatch(logout());
          }else{
            return response.json()
          }
        })
        //Then with the data from the response in JSON...
        .then((data) => {
          setType("status")
          setShow(true)
          setMessage(data.message)
          setActionLoader(false)
        })
        getofferlist();

  }

    function setpage(value){
      if (value >=1&& value<=lastPage){
          setCurrentPage(value)
      }
    }
    function searchParam(value,key){
     setSearchBy({...searchBy,[key]:value})
    }
    const searchstring=(searchBy)=>{
      let passdata={...searchBy};
      if(searchBy.startdate!=null && searchBy.enddate!=null){
        passdata.startdate=searchBy.startdate;
        passdata.enddate=searchBy.enddate;
      }
      var queryString = Object.keys(passdata).map(key => key + '=' + passdata[key]).join('&');
      return queryString
    }
    var arr=[...list]
    function sort(type,key){
      if(sortingby==1){
        //code for sorting on server 
        setSearchBy({...searchBy,"sortby":key,"sorttype":type});
      }else{
      // Code for sorting in browser 
        if (type=="asc"){
         for (let i=0;i<arr.length;i++){
             for (let j=0;j<=i;j++){
                 if (arr[i][key] < arr[j][key]) {
                     var x = arr[i];
                     arr[i] =arr[j];
                     arr[j] = x;
                 }
             }
         }
      }
      if (type=="desc"){
         for (let i=0;i<arr.length;i++){
             for (let j=0;j<=i;j++){
                 if (arr[i][key] >arr[j][key]) {
                     var x = arr[i];
                     arr[i] =arr[j];
                     arr[j] = x;
                 }
             }
         }
      }
     setList(arr);
      }
    }
    function returnoffer_id(offer_id){
      setType("confirm")
      setMessage("Are you sure you want to delete this Offer")
      setShow(true);
      setCoupon_id(offer_id);
      console.log("in get customer_id",offer_id)
    }
    function modelAction(show,descision,offer_id){
      setShow(show);
      if(descision){
        setActionLoader(true);
        deleteOffer(offer_id);
      }else{
        setCoupon_id(null);
      }
    }
    if(Login==null||Login==false){
    return  <Navigate to="/" replace={true} />
    }
     return (
       <>
        <DialogModal 
        show={show} 
        message={message} 
        action={modelAction} 
        id={coupon_id} 
        type={type}
        actionLoader={actionLoader}
       />
         <div className="container-fluid ps-0 pe-0">
           <div className="row">
             <div>
              <Navbarp />
             </div>
             <div className='position-absolute'>
                <div className='mt-1'>
                <Grid container  className='justify-content-between ms-3'>
                      <div className='d-md-flex width_100 me-3'>
                            <Grid item  className='me-3 mt-2 '>
                              <Card className='record' >
                                <CardContent>
                                  <Typography variant="h6" gutterBottom>
                                    Record Found
                                  </Typography>
                                  <Typography variant="h6" gutterBottom>
                                    {count}
                                  </Typography>
                                </CardContent>   
                              </Card>
                            </Grid>
                            <Grid item  className='me-3 mt-2'>
                              <Card className='total '>
                                <CardContent>
                                  <Typography variant="h6" gutterBottom>
                                    Total Offers
                                  </Typography>
                                  <Typography variant="h6" gutterBottom>
                                    {totalOffer}
                                  </Typography>
                                </CardContent>   
                              </Card>
                            </Grid>
                      </div>
                      <div>
                      <Grid container className='justify-content-between' >
                                    <Link to="addoffer">
                                      <button type="button" className="btn btn-success mt-4 ms-2 mb-2">
                                        <i className="bi bi-plus-lg me-1"/>
                                          Add Offer
                                      </button>
                                    </Link>
                                    <Calendar
                                      searchBy={searchBy} 
                                      setSearchBy={setSearchBy}
                                      setSortingBy={setSortingBy}
                                      lastPage={lastPage} 
                                      currentPage={currentPage} 
                                      setpage={setpage} 
                                    />
                      </Grid>
                    </div>                         
                  </Grid>
                  </div>
               
                  {loading?
                    <Loader/>:
                  <div>
                  <OfferList data={list} searchParam={searchParam} sort={sort} returnoffer_id={returnoffer_id}/>
                  </div>
                   }
                  
                </div>       
             </div>
           </div>
       
       </>
     );
}

export default Offers