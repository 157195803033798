import React from 'react'
import {useSelector} from 'react-redux'
import Select from 'react-select'

function PlanDropDown(props) {
    const plandata = useSelector((state) => state.plan.plan);
    const selectdata=plandata.map((data)=>{
      return {
      value:data.plan_id,
      label:data.name
      }
    })
    const index=selectdata.findIndex(checkplan)
    function checkplan(data) {
      return data.value== props.defaultValue;
    }
   // console.log(props.defaultValue)
  return (
    <div style={{width: "100%"}}>
      <Select 
        options={selectdata}  
        onChange={(value)=>{props?.searchParam ? props.searchParam(value.value,"plan_id"):props.action(value.value)}}
        defaultValue={selectdata[index]}
      />
    </div>
      

    // <select 
    //   className="form-select text-center"
    //   aria-label="Default select example" style={{width: "170px"}}
    //   onChange={e=>{props.searchParam(e.target.value,"plan_id")}}>
    //                   <option value="">Select Plan</option>
    //                   {plandata?.plan?.map((data) => {
    //                     return(
    //                         <option key={data.plan_id} value={data.plan_id}>
    //                           {data.name}
    //                         </option>
    //                     )
    //                   })}
    //                  </select>
  )
}

export default PlanDropDown