import React, { useState } from 'react'
import '../../css/Sidenav.css'
import { Link, NavLink } from 'react-router-dom'
function Menubar() {
  let activeClass="selected";
  let defaultClass="text";
  return (
        <nav className="navbar navbar-expand-xxl ">
        <div className="container-fluid sidenav">
    <button className="navbar-toggler ms-2 mt-1 mb-1 mt-1" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse justify-content-between " id="navbarNav">
              <div className="nav-item">
              <NavLink  className={({ isActive }) =>
              isActive ? activeClass : defaultClass} to="/dashboard"><i className="bi bi-tv me-2"></i>DASHBOARD</NavLink>
              </div>
              <div className="nav-item">
              <NavLink className={({ isActive }) =>
              isActive ? activeClass : defaultClass} to="/customers"><i className="bi bi-people me-2"></i>USERS</NavLink>
              </div>
              <div className="nav-item">
              <NavLink  className={({ isActive }) =>
              isActive ? activeClass : defaultClass} to="/dairy"><i className="bi bi-shop-window me-2"></i>DAIRY CENTER</NavLink>
              </div>
              <div className="nav-item">
              <NavLink  className={({ isActive }) =>
              isActive ? activeClass : defaultClass} to="/orders"><i className="bi bi-cart3 me-2"></i>ORDERS</NavLink>
              </div>
              <div className="nav-item">
              <NavLink  className={({ isActive }) =>
              isActive ? activeClass : defaultClass} to="/payments"><i className="bi bi-cash me-2"></i>PAYMENTS</NavLink>
              </div>
              <div className="nav-item">
              <NavLink  className={({ isActive }) =>
              isActive ? activeClass : defaultClass} to="/subscriptions"><i className="bi bi-calendar2-minus me-2"></i>SUBSCRIPTIONS</NavLink>
              </div>
              <div className="nav-item">
              <NavLink  className={({ isActive }) =>
              isActive ? activeClass : defaultClass} to="/coupons"><i className="bi bi-percent me-2"></i>COUPONS</NavLink>
              </div>
              <div className="nav-item">
              <NavLink  className={({ isActive }) =>
              isActive ? activeClass : defaultClass} to="/offers"><i className="bi bi-cash me-2"></i>OFFERS</NavLink>
              </div>
              <div className="nav-item">
              <NavLink  className={({ isActive }) =>
              isActive ? activeClass : defaultClass} to="/plans"><i className="bi bi-list me-2"></i>PLANS</NavLink>
              </div>
              <div className="nav-item">
              <NavLink  className={({ isActive }) =>
              isActive ? activeClass : defaultClass}  to="/ratelist"><i className="bi bi-list-columns me-2"></i>PREDEFINED RATELIST</NavLink>        
              </div>
      
    </div>
  </div>
</nav>    

 
      
        
    
  
  )
}

export default Menubar