export  function checkspecial(data){   
  var pattern=/[~`!@#$%^&*()+=|{}:;"',<>.?\[\/\\\]]/g; 
    var result=pattern.test(data)
    return result 
}
export function required(data) {
  if (data=="") {
    return true
  } else {
    return false;
  }
}
export function checkminlenght(data,minlength) {
  if(data.length<minlength) {
    return true
  }

}
export function checkmaxlenght(data,maxlength) {
  if(data.length>maxlength) {
    return true
  }
}
export function checklenght(data,length) {
  if(data.length=length) {
    return true
  }
}

export function checkuppercase(data){
  var pattern=/[A-Z]/g
  var result=pattern.test(data)
  return result;
}
export function checklowercase(data){
  var pattern=/[a-z]/g
  var result=pattern.test(data)
  return result;
}
export function checknumber(data){
  var pattern=/[0-9]/g
  var result=pattern.test(data)
  return result;
}

export function checkemail(data){
  let pattern = /[a-zA-Z_0-9]\w*@[a-z]\w*\.[a-z]{2,3}/gm;
  var result=pattern.test(data)
  return result
}  

    
  

 
      