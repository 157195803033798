import {createSlice} from '@reduxjs/toolkit'

export const loginuser=createSlice({
    name :"loginuser",
    initialState:{
        Login:JSON.parse(localStorage.getItem('Login')),
        token:localStorage.getItem('token'),
        userDetail:JSON.parse(localStorage.getItem('userDetail')),
        tempToken:"",
    },
    reducers:{
        setTempToken:(state,action)=>{
            state.tempToken=action.payload;
        },
        setlogin:(state,action)=>{  
            state.Login=true;
            state.token=action.payload.token;
            state.userDetail={...action.payload.userDetail}
            localStorage.setItem('Login',state.Login);
            localStorage.setItem('token',state.token);
            localStorage.setItem('userDetail',JSON.stringify(state.userDetail))          
        },
        logout:(state)=>{
            state.Login=false;
            state.token=null;
            state.userDetail=null
            state.tempToken=null;
            localStorage.removeItem('Login');
            localStorage.removeItem('token');
            localStorage.removeItem('userDetail')
            localStorage.removeItem('plan')
            localStorage.removeItem('coupon')
        }

    }
})

export const { setlogin,logout,setTempToken} = loginuser.actions;

export default loginuser.reducer